import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Container,
  Typography,
  Grid,
  Modal,
  Snackbar,
  Alert,
  Paper,
  IconButton,
  Toolbar,
  CssBaseline,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialEquipamentoState = {
  codigo_equipamento: '',
  nome_equipamento: '',
  descricao_equipamento: '',
  categoria: '',
  data_aquisicao: '',
  vida_util_estimada: '',
  status_equipamento: '',
  fornecedor_id: '',
  custo_aquisicao: '',
  localizacao: '',
  observacoes: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

export default function Equipamentos() {
  const [equipamentos, setEquipamentos] = useState([]);
  const [equipamento, setEquipamento] = useState(initialEquipamentoState);
  const [fornecedores, setFornecedores] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true); // Controlar abertura do menu lateral

  useEffect(() => {
    fetchEquipamentos();
    fetchFornecedores();
  }, []);

  const fetchEquipamentos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/equipamentos`);
      setEquipamentos(response.data);
    } catch (error) {
      console.error('Erro ao buscar equipamentos:', error);
    }
  };
  
  const fetchFornecedores = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/fornecedores`);
      setFornecedores(response.data);
    } catch (error) {
      console.error('Erro ao buscar fornecedores:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEquipamento({ ...equipamento, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Certifique-se de que campos obrigatórios estão preenchidos
    if (!equipamento.codigo_equipamento || !equipamento.nome_equipamento) {
      setSnackbarMessage('Código e Nome do Equipamento são obrigatórios!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
  
    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/equipamentos/${equipamento.id}`, equipamento);
        setSnackbarMessage('Equipamento atualizado com sucesso!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/equipamentos`, equipamento);
        setSnackbarMessage('Equipamento cadastrado com sucesso!');
      }
  
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setEquipamento(initialEquipamentoState);
      setIsEditing(false);
      setOpen(false);
      fetchEquipamentos();
    } catch (error) {
      console.error('Erro ao salvar equipamento:', error.response ? error.response.data : error.message);
      setSnackbarMessage('Erro ao salvar equipamento!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  

  const handleEdit = (equipamento) => {
    setEquipamento(equipamento);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/equipamentos/${id}`);
      setSnackbarMessage('Equipamento deletado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchEquipamentos(); // Atualizar a lista após exclusão
    } catch (error) {
      setSnackbarMessage('Erro ao deletar equipamento!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao deletar equipamento:', error);
    }
  };
  

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEquipamento(initialEquipamentoState);
    setIsEditing(false);
  };
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => setMenuOpen(!menuOpen); // Controlar abertura/fechamento do menu

  const columns = [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'codigo_equipamento', header: 'Código' },
    { accessorKey: 'nome_equipamento', header: 'Nome' },
    { accessorKey: 'categoria', header: 'Categoria' },
    { accessorKey: 'status_equipamento', header: 'Status' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} /> {/* Adicionando o Header */}
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} /> {/* Adicionando o Menu lateral */}
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de Equipamentos
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Novo Equipamento
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={equipamentos}
                  enableColumnOrdering
                  enablePagination
                  enableSorting
                />
              </Paper>
            </Container>
          </Box>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Equipamento' : 'Cadastrar Equipamento'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Código do Equipamento"
                    name="codigo_equipamento"
                    value={equipamento.codigo_equipamento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome do Equipamento"
                    name="nome_equipamento"
                    value={equipamento.nome_equipamento}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descrição do Equipamento"
                    name="descricao_equipamento"
                    value={equipamento.descricao_equipamento}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Categoria"
                    name="categoria"
                    value={equipamento.categoria}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    fullWidth
                    label="Data de Aquisição"
                    name="data_aquisicao"
                    value={equipamento.data_aquisicao}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Vida Útil Estimada"
                    name="vida_util_estimada"
                    value={equipamento.vida_util_estimada}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Status do Equipamento"
                    name="status_equipamento"
                    value={equipamento.status_equipamento}
                    onChange={handleChange}
                  >
                    <MenuItem value="Ativo">Ativo</MenuItem>
                    <MenuItem value="Inativo">Inativo</MenuItem>
                    <MenuItem value="Em Manutenção">Em Manutenção</MenuItem>
                    <MenuItem value="Aposentado">Aposentado</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Fornecedor"
                    name="fornecedor_id"
                    value={equipamento.fornecedor_id}
                    onChange={handleChange}
                  >
                    {fornecedores.map((fornecedor) => (
                      <MenuItem key={fornecedor.id} value={fornecedor.id}>
                        {fornecedor.nome_razao_social}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Custo de Aquisição"
                    name="custo_aquisicao"
                    value={equipamento.custo_aquisicao}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Localização"
                    name="localizacao"
                    value={equipamento.localizacao}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Observações"
                    name="observacoes"
                    value={equipamento.observacoes}
                    onChange={handleChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
