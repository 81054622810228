import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Container,
  Typography,
  Grid,
  Modal,
  IconButton,
  Snackbar,
  Alert,
  CssBaseline,
  Toolbar,
  Paper,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Header from '../../Header';
import Menu from '../../Menu';

const API_URL = process.env.REACT_APP_API_URL;

const defaultTheme = createTheme();

const initialClientState = {
  empresa_id: '',
  nome_completo: '',
  cpf_cnpj: '',
  rg_ie: '',
  data_nascimento: '',
  sexo: '',
  telefone_fixo: '',
  celular: '',
  email: '',
  website: '',
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  observacoes: '',
  limite_credito: '',
  tipo_cliente: '',
  status_cliente: '',
  ramo_atividade: '',
  origem_cliente: '',
  forma_pagamento_preferida: '',
  representante_comercial: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

// Lista fixa de empresas para o dropdown
const empresas = [
  { id: 1, nome: 'AABB Engenharia' },
  { id: 2, nome: 'Chalé' },
  { id: 3, nome: 'Concreto' },
];

export default function Clientes() {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(initialClientState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/clientes`);
      setClients(response.data);
    } catch (error) {
      console.error('Erro ao buscar clientes:', error.response ? error.response.data : error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    if (!client.data_nascimento || client.data_nascimento === '') {
      client.data_nascimento = null;
    }

    try {
      let response;
      if (isEditing) {
        response = await axios.put(`${API_URL}/api/clientes/${client.id}`, client);
        setSnackbarMessage('Cliente atualizado com sucesso!');
      } else {
        response = await axios.post(`${API_URL}/api/clientes`, client);
        setSnackbarMessage('Cliente cadastrado com sucesso!');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setClient(initialClientState);
      setIsEditing(false);
      setOpen(false);
      fetchClients();
    } catch (error) {
      console.error('Erro ao salvar cliente:', error.response ? error.response.data : error.message);
      setSnackbarMessage(error.response ? error.response.data.message : 'Erro ao salvar cliente!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [client, isEditing]);

  const handleEdit = (client) => {
    setClient({
      ...client,
      data_nascimento: client.data_nascimento ? client.data_nascimento.split('T')[0] : '',
    });
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/api/clientes/${id}`);
      if (response.status === 200) {
        setSnackbarMessage('Cliente excluído com sucesso!');
        setSnackbarSeverity('success');
        fetchClients();
      } else {
        setSnackbarMessage('Erro ao deletar cliente.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('Erro ao deletar cliente.');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  const handleView = (client) => {
    setClient(client);
    setViewOpen(true);
  };

  const handleOpen = () => {
    setClient(initialClientState);
    setIsEditing(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setClient(initialClientState);
    setIsEditing(false);
  };

  const handleViewClose = () => setViewOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  const handlePrint = () => {
    window.print();
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(clients);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clientes');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, `Clientes_${new Date().toISOString()}.xlsx`);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Lista de Clientes', 20, 10);
    doc.autoTable({
      head: [['Nome Completo', 'CPF/CNPJ', 'Telefone', 'Email']],
      body: clients.map((client) => [
        client.nome_completo,
        client.cpf_cnpj,
        client.telefone_fixo || client.celular,
        client.email,
      ]),
    });
    doc.save(`Clientes_${new Date().toISOString()}.pdf`);
  };

  const columns = [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'nome_completo', header: 'Nome Completo' },
    { accessorKey: 'cpf_cnpj', header: 'CPF/CNPJ' },
    { accessorKey: 'telefone_fixo', header: 'Telefone Fixo' },
    { accessorKey: 'celular', header: 'Celular' },
    { accessorKey: 'email', header: 'Email' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleView(row.original)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de Clientes
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Novo Cliente
                </Button>
                <Box>
                  <IconButton
                    color="primary"
                    onClick={exportToExcel}
                    sx={{ marginRight: 2 }}
                  >
                    <SaveAltIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={exportToPDF}
                    sx={{ marginRight: 2 }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={handlePrint}
                    sx={{ marginRight: 2 }}
                  >
                    <PrintIcon />
                  </IconButton>
                </Box>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={clients}
                  enableColumnOrdering
                  enableRowSelection
                  enablePagination
                  enableSorting
                  muiTableBodyRowProps={{ hover: true }}
                  muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                  pageSizeOptions={[10, 25, 50]}
                  initialState={{ pagination: { pageSize: 50 } }}
                />
              </Paper>
            </Container>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Cliente' : 'Cadastrar Cliente'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome Completo"
                    name="nome_completo"
                    value={client.nome_completo}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CPF/CNPJ"
                    name="cpf_cnpj"
                    value={client.cpf_cnpj}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="RG/Inscrição Estadual"
                    name="rg_ie"
                    value={client.rg_ie}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    fullWidth
                    label="Data de Nascimento/Fundação"
                    name="data_nascimento"
                    InputLabelProps={{ shrink: true }}
                    value={client.data_nascimento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Sexo"
                    name="sexo"
                    value={client.sexo}
                    onChange={handleChange}
                  >
                    <MenuItem value="Masculino">Masculino</MenuItem>
                    <MenuItem value="Feminino">Feminino</MenuItem>
                    <MenuItem value="Outro">Outro</MenuItem>
                  </TextField>
                </Grid>

                {/* Campo para selecionar a empresa */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Empresa</InputLabel>
                    <Select
                      name="empresa_id"
                      value={client.empresa_id}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Escolha uma empresa</em>
                      </MenuItem>
                      {empresas.map((empresa) => (
                        <MenuItem key={empresa.id} value={empresa.id}>
                          {empresa.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Telefone Fixo"
                    name="telefone_fixo"
                    value={client.telefone_fixo}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Celular"
                    name="celular"
                    value={client.celular}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={client.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Website"
                    name="website"
                    value={client.website}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CEP"
                    name="cep"
                    value={client.cep}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Endereço"
                    name="endereco"
                    value={client.endereco}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Número"
                    name="numero"
                    value={client.numero}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    name="complemento"
                    value={client.complemento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    name="bairro"
                    value={client.bairro}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="cidade"
                    value={client.cidade}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Estado"
                    name="estado"
                    value={client.estado}
                    onChange={handleChange}
                  >
                    <MenuItem value="AC">Acre</MenuItem>
                    <MenuItem value="AL">Alagoas</MenuItem>
                    <MenuItem value="AP">Amapá</MenuItem>
                    <MenuItem value="AM">Amazonas</MenuItem>
                    <MenuItem value="BA">Bahia</MenuItem>
                    <MenuItem value="CE">Ceará</MenuItem>
                    <MenuItem value="DF">Distrito Federal</MenuItem>
                    <MenuItem value="ES">Espírito Santo</MenuItem>
                    <MenuItem value="GO">Goiás</MenuItem>
                    <MenuItem value="MA">Maranhão</MenuItem>
                    <MenuItem value="MT">Mato Grosso</MenuItem>
                    <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                    <MenuItem value="MG">Minas Gerais</MenuItem>
                    <MenuItem value="PA">Pará</MenuItem>
                    <MenuItem value="PB">Paraíba</MenuItem>
                    <MenuItem value="PR">Paraná</MenuItem>
                    <MenuItem value="PE">Pernambuco</MenuItem>
                    <MenuItem value="PI">Piauí</MenuItem>
                    <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                    <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                    <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                    <MenuItem value="RO">Rondônia</MenuItem>
                    <MenuItem value="RR">Roraima</MenuItem>
                    <MenuItem value="SC">Santa Catarina</MenuItem>
                    <MenuItem value="SP">São Paulo</MenuItem>
                    <MenuItem value="SE">Sergipe</MenuItem>
                    <MenuItem value="TO">Tocantins</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Tipo de Cliente"
                    name="tipo_cliente"
                    value={client.tipo_cliente}
                    onChange={handleChange}
                  >
                    <MenuItem value="Pessoa Física">Pessoa Física</MenuItem>
                    <MenuItem value="Pessoa Jurídica">Pessoa Jurídica</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Status do Cliente"
                    name="status_cliente"
                    value={client.status_cliente}
                    onChange={handleChange}
                  >
                    <MenuItem value="Ativo">Ativo</MenuItem>
                    <MenuItem value="Inativo">Inativo</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Observações"
                    name="observacoes"
                    value={client.observacoes}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Ramo de Atividade"
                    name="ramo_atividade"
                    value={client.ramo_atividade}
                    onChange={handleChange}
                  >
                    <MenuItem value="Comércio">Comércio</MenuItem>
                    <MenuItem value="Indústria">Indústria</MenuItem>
                    <MenuItem value="Serviços">Serviços</MenuItem>
                    <MenuItem value="Outro">Outro</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Origem do Cliente"
                    name="origem_cliente"
                    value={client.origem_cliente}
                    onChange={handleChange}
                  >
                    <MenuItem value="Indicação">Indicação</MenuItem>
                    <MenuItem value="Redes Sociais">Redes Sociais</MenuItem>
                    <MenuItem value="Outros">Outros</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Forma de Pagamento Preferida"
                    name="forma_pagamento_preferida"
                    value={client.forma_pagamento_preferida}
                    onChange={handleChange}
                  >
                    <MenuItem value="Boleto">Boleto</MenuItem>
                    <MenuItem value="Cartão de Crédito">Cartão de Crédito</MenuItem>
                    <MenuItem value="Transferência Bancária">Transferência Bancária</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Limite de Crédito"
                    name="limite_credito"
                    value={client.limite_credito}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Representante Comercial"
                    name="representante_comercial"
                    value={client.representante_comercial}
                    onChange={handleChange}
                  >
                    <MenuItem value="Representante 1">Representante 1</MenuItem>
                    <MenuItem value="Representante 2">Representante 2</MenuItem>
                    <MenuItem value="Representante 3">Representante 3</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
        <Modal
          open={viewOpen}
          onClose={handleViewClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleViewClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Visualizar Cliente
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Nome Completo:</strong> {client.nome_completo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>CPF/CNPJ:</strong> {client.cpf_cnpj}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>RG/Inscrição Estadual:</strong> {client.rg_ie}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Data de Nascimento/Fundação:</strong> {client.data_nascimento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Sexo:</strong> {client.sexo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Telefone Fixo:</strong> {client.telefone_fixo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Celular:</strong> {client.celular}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Email:</strong> {client.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Website:</strong> {client.website}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>CEP:</strong> {client.cep}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Endereço:</strong> {client.endereco}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Número:</strong> {client.numero}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Complemento:</strong> {client.complemento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Bairro:</strong> {client.bairro}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Cidade:</strong> {client.cidade}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Estado:</strong> {client.estado}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Tipo de Cliente:</strong> {client.tipo_cliente}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Status do Cliente:</strong> {client.status_cliente}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Observações:</strong> {client.observacoes}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Ramo de Atividade:</strong> {client.ramo_atividade}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Origem do Cliente:</strong> {client.origem_cliente}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Forma de Pagamento Preferida:</strong> {client.forma_pagamento_preferida}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Limite de Crédito:</strong> {client.limite_credito}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Representante Comercial:</strong> {client.representante_comercial}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
