import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import BusinessIcon from '@mui/icons-material/Business';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';
import TaskIcon from '@mui/icons-material/Task';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

export function MainListItems({ open }) {
  const location = useLocation();
  const [openMenu, setOpenMenu] = React.useState({});

  React.useEffect(() => {
    if (
      location.pathname.includes('/clientes') ||
      location.pathname.includes('/funcionarios') ||
      location.pathname.includes('/produtos') ||
      location.pathname.includes('/servicos') ||
      location.pathname.includes('/equipamentos') ||
      location.pathname.includes('/parceiros')
    ) {
      setOpenMenu((prevOpen) => ({
        ...prevOpen,
        cadastros: true,
      }));
    }
  }, [location.pathname]);

  const handleClick = (menu) => {
    setOpenMenu((prevOpen) => ({
      ...prevOpen,
      [menu]: !prevOpen[menu],
    }));
  };

  return (
    <React.Fragment>
      {/* Logo Section */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px 8px' }}>
        <img
          src="https://aabbengenharia.com.br/wp-content/uploads/2022/09/thumb.png"
          alt="Logo"
          style={{ width: '70%', padding: '8px' }}
        />
      </Box>
      <Divider />

      {/* Dashboard */}
      <Tooltip title="Dashboard" placement="right" disableHoverListener={open}>
        <ListItemButton
          component={Link}
          to="/dashboard"
          sx={{
            padding: '8px 16px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </Tooltip>

      {/* Cadastros (Submenu) */}
      <Tooltip title="Cadastros" placement="right" disableHoverListener={open}>
        <ListItemButton
          onClick={() => handleClick('cadastros')}
          sx={{
            padding: '8px 16px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastros" />
          {openMenu.cadastros ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Tooltip>
      <Collapse in={openMenu.cadastros} timeout="auto" unmountOnExit>
        <ListItemButton sx={{ pl: 4 }} component={Link} to="/clientes">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Clientes" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }} component={Link} to="/funcionarios">
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Funcionários" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }} component={Link} to="/produtos">
          <ListItemIcon>
            <WorkOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Produtos" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }} component={Link} to="/servicos">
          <ListItemIcon>
            <WorkOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Serviços" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }} component={Link} to="/equipamentos">
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Equipamentos" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }} component={Link} to="/fornecedores">
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Fornecedores" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }} component={Link} to="/parceiros">
          <ListItemIcon>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary="Parceiros" />
        </ListItemButton>
      </Collapse>

      {/* Financeiro */}
      <Tooltip title="Financeiro" placement="right" disableHoverListener={open}>
        <ListItemButton
          component={Link}
          to="/Financeiro"
          sx={{
            padding: '8px 16px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Financeiro" />
        </ListItemButton>
      </Tooltip>

      {/* Projetos */}
      <Tooltip title="Projetos" placement="right" disableHoverListener={open}>
        <ListItemButton
          component={Link}
          to="/projetos"
          sx={{
            padding: '8px 16px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Projetos" />
        </ListItemButton>
      </Tooltip>

      {/* Tarefas */}
      <Tooltip title="Tarefas" placement="right" disableHoverListener={open}>
        <ListItemButton
          component={Link}
          to="/tarefas"
          sx={{
            padding: '8px 16px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <ListItemIcon>
            <TaskIcon />
          </ListItemIcon>
          <ListItemText primary="Tarefas" />
        </ListItemButton>
      </Tooltip>

      {/* Configurações (Submenu) */}
      <Tooltip title="Configurações" placement="right" disableHoverListener={open}>
        <ListItemButton
          onClick={() => handleClick('configuracoes')}
          sx={{
            padding: '8px 16px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Configurações" />
          {openMenu.configuracoes ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Tooltip>
      <Collapse in={openMenu.configuracoes} timeout="auto" unmountOnExit>
        <ListItemButton sx={{ pl: 4 }} component={Link} to="/usuarios">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Usuários" />
        </ListItemButton>
      </Collapse>
    </React.Fragment>
  );
}
