import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Header from '../../Header';
import Menu from '../../Menu';
import { Button, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import PaymentIcon from '@mui/icons-material/Payment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const defaultTheme = createTheme();

const commonStyles = {
  paper: {
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  icon: {
    fontSize: 50,
    color: 'primary.main',
    mb: 2,
  },
  button: {
    mt: 2,
  },
  description: {
    mt: 1,
    textAlign: 'center',
    color: 'text.secondary',
  },
};

// Componente Genérico para os módulos financeiros
const FinanceiroCard = ({ title, description, path, Icon }) => {
  const navigate = useNavigate();

  return (
    <Paper sx={commonStyles.paper} elevation={4}>
      <Icon sx={commonStyles.icon} />
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Typography sx={commonStyles.description}>
        {description}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={commonStyles.button}
        onClick={() => navigate(path)}
      >
        Gerenciar
      </Button>
    </Paper>
  );
};

const FinanceiroDashboard = () => {
  const [menuOpen, setMenuOpen] = React.useState(true);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={() => setMenuOpen(!menuOpen)} />
        <Menu open={menuOpen} toggleDrawer={() => setMenuOpen(!menuOpen)} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" gutterBottom align="center">
              Financeiro
            </Typography>
            <Typography variant="subtitle1" gutterBottom align="center">
              Gerencie todas as operações financeiras da sua empresa.
            </Typography>

            <Grid container spacing={4}>
              {/* Módulos financeiros utilizando o componente genérico */}
              <Grid item xs={12} md={6} lg={4}>
                <FinanceiroCard
                  title="Categorias de Transações"
                  description="Organize e classifique todas as receitas e despesas da empresa em categorias apropriadas."
                  path="/CategoriaTransacoes"
                  Icon={CategoryIcon}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FinanceiroCard
                  title="Clientes"
                  description="Gerencie as informações dos clientes e mantenha o controle das contas a receber."
                  path="/Clientes"
                  Icon={PeopleIcon}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FinanceiroCard
                  title="Fornecedores"
                  description="Gerencie os dados dos fornecedores e as contas a pagar associadas a eles."
                  path="/Fornecedores"
                  Icon={BusinessIcon}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FinanceiroCard
                  title="Contas a Pagar"
                  description="Acompanhe todas as despesas e pagamentos devidos aos fornecedores."
                  path="/ContasPagar"
                  Icon={PaymentIcon}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FinanceiroCard
                  title="Contas a Receber"
                  description="Controle todas as receitas da empresa e os recebimentos pendentes."
                  path="/ContasReceber"
                  Icon={MonetizationOnIcon}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FinanceiroCard
                  title="Fluxo de Caixa"
                  description="Acompanhe as entradas e saídas de caixa em tempo real para um controle preciso."
                  path="/FluxoCaixa"
                  Icon={AccountBalanceIcon}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FinanceiroCard
                  title="Funcionários"
                  description="Gerencie os dados dos funcionários e os pagamentos associados a eles."
                  path="/FinanceiroFuncionarios"
                  Icon={WorkIcon}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FinanceiroCard
                  title="Projetos"
                  description="Vincule receitas e despesas a projetos específicos para um acompanhamento detalhado."
                  path="/Projetos"
                  Icon={AssignmentIcon}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FinanceiroCard
                  title="Transações Financeiras"
                  description="Monitore todas as transações financeiras da empresa e registre cada movimentação."
                  path="/TransacoesFinanceiras"
                  Icon={TrendingUpIcon}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FinanceiroDashboard;
