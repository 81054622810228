import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon'; // Para usar o ícone SVG do Todoist

// Componente para exibir o rodapé com direitos autorais
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Desenvolvido por © '}
      <a color="inherit" href="https://athix.com.br/">
        ATHIX
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// Ícone SVG do Todoist
function TodoistIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm3 10.5a1 1 0 1 0 0 2h12a1 1 0 0 0 0-2H6Zm0-4a1 1 0 1 0 0 2h12a1 1 0 0 0 0-2H6Zm0-4a1 1 0 1 0 0 2h12a1 1 0 0 0 0-2H6Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

// Tema personalizado
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#7a7a7a', // Cor moderna
    },
    background: {
      default: '#f0f4f8', // Cor de fundo suave
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif', // Fonte clean
  },
});

// Componente de alerta para o Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SignInSide() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  const [openTodoistSnackbar, setOpenTodoistSnackbar] = React.useState(true); // Snackbar para o Todoist

  // Carregar dados salvos (se lembrar-me foi selecionado)
  React.useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';

    if (savedRememberMe) {
      setEmail(savedEmail || '');
      setPassword(savedPassword || '');
      setRememberMe(true);
    }

    // Fechar o aviso sobre o Todoist após 5 segundos
    const timer = setTimeout(() => {
      setOpenTodoistSnackbar(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  // Fechar o Snackbar
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Verificar se o email e a senha estão preenchidos
    console.log('Email:', email, 'Senha:', password);
  
    if (!email || !password) {
      setSnackbarMessage('Email e senha são obrigatórios.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
  
    try {
      // Realiza a requisição para o backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, senha: password }), // Enviando o campo correto
      });
    
      const data = await response.json();
      console.log('Resposta do servidor:', data);
    
      if (response.ok) {
        // Salva o token no localStorage e navega para o dashboard
        localStorage.setItem('token', data.token);
        localStorage.setItem('loginTime', Date.now());
        
        // Mensagem de sucesso
        setSnackbarMessage(data.message);
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setTimeout(() => navigate('/dashboard'), 1500);
      } else {
        // Exibir mensagem de erro
        setSnackbarMessage(data.message);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error('Erro durante a requisição:', error);
      setSnackbarMessage('Erro ao realizar login');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#ffffff',
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              p: 4,
              width: '100%',
              maxWidth: '400px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '8px',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.1)',
            }}
          >
            <img
              alt="Logo"
              src="https://aabbengenharia.com.br/wp-content/uploads/2022/09/thumb.png"
              style={{ width: '150px', marginBottom: '20px' }}
            />
            
            {/* Botão para acompanhar o projeto no Todoist */}
            <Tooltip title="Acompanhe o progresso do projeto no Todoist" placement="top">
              <IconButton
                component="a"
                href="https://app.todoist.com/app/project/erp-6RHwQF9G8PXhPgvq"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: '#1976d2', marginBottom: '20px' }}
              >
                <TodoistIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                name="password"
                label="Senha"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    color="primary"
                  />
                }
                label="Lembrar-me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, borderRadius: '30px' }}
              >
                Entrar
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Componente Snackbar para exibir notificações */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar temporário para informar sobre o Todoist */}
      <Snackbar
        open={openTodoistSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenTodoistSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenTodoistSnackbar(false)} severity="info">
          Você pode acompanhar o progresso do projeto no Todoist. Clique no ícone abaixo.
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
