import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, MenuItem, Container, Typography,
  Grid, Modal, IconButton, Snackbar, Alert, CssBaseline, Toolbar, Paper,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialReceivableState = {
  descricao: '',
  valor: '',
  data_vencimento: '',
  data_recebimento: '',
  status_recebimento: 'Pendente',
  id_cliente: '',
  id_projeto: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

export default function ContasReceber() {
  const [receivables, setReceivables] = useState([]);
  const [receivable, setReceivable] = useState(initialReceivableState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    fetchReceivables();
  }, []);

  const fetchReceivables = async () => {
    try {
      const response = await axios.get('https://api.aabbengenharia.com.br/api/financeiro/contasreceber');
      setReceivables(response.data);
    } catch (error) {
      console.error('Erro ao buscar contas a receber:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReceivable({ ...receivable, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const cleanedReceivable = {
        ...receivable,
        id_cliente: receivable.id_cliente || null,
        id_projeto: receivable.id_projeto || null,
        data_recebimento: receivable.data_recebimento || null,
      };

      if (isEditing) {
        await axios.put(`https://api.aabbengenharia.com.br/api/financeiro/contasreceber/${receivable.id}`, cleanedReceivable);
        setSnackbarMessage('Conta a receber atualizada com sucesso!');
      } else {
        await axios.post('https://api.aabbengenharia.com.br/api/financeiro/contasreceber', cleanedReceivable);
        setSnackbarMessage('Conta a receber cadastrada com sucesso!');
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setReceivable(initialReceivableState);
      setIsEditing(false);
      setOpen(false);
      fetchReceivables();
    } catch (error) {
      setSnackbarMessage('Erro ao salvar conta a receber!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao salvar conta a receber:', error.response ? error.response.data : error.message);
    }
  };

  const handleEdit = (receivable) => {
    setReceivable(receivable);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.aabbengenharia.com.br/api/financeiro/contasreceber/${id}`);
      setSnackbarMessage('Conta a receber deletada com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchReceivables();
    } catch (error) {
      setSnackbarMessage('Erro ao deletar conta a receber!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao deletar conta a receber:', error);
    }
  };

  const handleView = async (id) => {
    try {
      const response = await axios.get(`https://api.aabbengenharia.com.br/api/financeiro/contasreceber/${id}`);
      setReceivable(response.data);
      setViewOpen(true);
    } catch (error) {
      console.error('Erro ao visualizar conta a receber:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setReceivable(initialReceivableState);
    setIsEditing(false);
  };
  const handleViewClose = () => setViewOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  const columns = [
    { accessorKey: 'descricao', header: 'Descrição' },
    { accessorKey: 'valor', header: 'Valor' },
    { accessorKey: 'data_vencimento', header: 'Data de Vencimento' },
    { accessorKey: 'data_recebimento', header: 'Data de Recebimento' },
    { accessorKey: 'status_recebimento', header: 'Status' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleView(row.original.id)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Contas a Receber
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Nova Conta a Receber
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={receivables}
                  enableColumnOrdering
                  enableRowSelection
                  enablePagination
                  enableSorting
                  muiTableBodyRowProps={{ hover: true }}
                  muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                  pageSizeOptions={[10, 25, 50]}
                  initialState={{ pagination: { pageSize: 50 } }}
                />
              </Paper>
            </Container>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Conta a Receber' : 'Cadastrar Conta a Receber'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Descrição"
                    name="descricao"
                    value={receivable.descricao}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Valor"
                    name="valor"
                    type="number"
                    value={receivable.valor}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data de Vencimento"
                    name="data_vencimento"
                    type="date"
                    value={receivable.data_vencimento}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data de Recebimento"
                    name="data_recebimento"
                    type="date"
                    value={receivable.data_recebimento}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Status"
                    name="status_recebimento"
                    value={receivable.status_recebimento}
                    onChange={handleChange}
                  >
                    <MenuItem value="Pendente">Pendente</MenuItem>
                    <MenuItem value="Recebido">Recebido</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="ID Cliente"
                    name="id_cliente"
                    value={receivable.id_cliente}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="ID Projeto"
                    name="id_projeto"
                    value={receivable.id_projeto}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
        <Modal
          open={viewOpen}
          onClose={handleViewClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleViewClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Visualizar Conta a Receber
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Descrição:</strong> {receivable.descricao}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Valor:</strong> {receivable.valor}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Data de Vencimento:</strong> {receivable.data_vencimento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Data de Recebimento:</strong> {receivable.data_recebimento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Status:</strong> {receivable.status_recebimento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>ID Cliente:</strong> {receivable.id_cliente}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>ID Projeto:</strong> {receivable.id_projeto}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
