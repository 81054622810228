import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import Footer from './components/Footer';
import Clientes from './Cadastros/Clientes/Clientes';
import Funcionarios from './Cadastros/Funcionarios/Funcionarios';
import Produtos from './Cadastros/Produtos/Produtos';
import Servicos from './Cadastros/Servicos/Servicos';
import Financeiro from './Cadastros/Financeiro/Financeiro';
import TransacoesFinanceiras from './Cadastros/Financeiro/TransacoesFinanceiras';
import CategoriaTransacoes from './Cadastros/Financeiro/CategoriaTransacoes';
import ContasPagar from './Cadastros/Financeiro/ContasPagar';
import ContasReceber from './Cadastros/Financeiro/ContasReceber';
import FluxoCaixa from './Cadastros/Financeiro/FluxoCaixa';
import FinanceiroFuncionarios from './Cadastros/Financeiro/Funcionarios';
import Fornecedores from './Cadastros/Fornecedores/Fornecedores';
import Equipamentos from './Cadastros/Equipamentos/Equipamentos';
import Parceiros from './Cadastros/Parceiros/Parceiros';
import Usuarios from './Cadastros/Usuarios/Usuarios';

import Menu from './Menu';
import ProtectedRoute from './ProtectedRoute';  // Importando o componente de Rota Protegida

const theme = createTheme({
  palette: {
    primary: {
      main: '#007BFF',
    },
    secondary: {
      main: '#FF5733',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />  {/* Redireciona para a página de login */}
          <Route path="/login" element={<Login />} />  {/* Página de login */}
          
          {/* Páginas protegidas */}
          <Route 
            path="/dashboard" 
            element={<ProtectedRoute><Dashboard /></ProtectedRoute>} 
          />
          <Route 
            path="/menu" 
            element={<ProtectedRoute><Menu /></ProtectedRoute>} 
          />
          <Route 
            path="/clientes" 
            element={<ProtectedRoute><Clientes /></ProtectedRoute>} 
          />
          <Route 
            path="/funcionarios" 
            element={<ProtectedRoute><Funcionarios /></ProtectedRoute>} 
          />
          <Route 
            path="/Produtos" 
            element={<ProtectedRoute><Produtos /></ProtectedRoute>} 
          />
          <Route 
            path="/Servicos" 
            element={<ProtectedRoute><Servicos /></ProtectedRoute>} 
          />
          <Route 
            path="/Financeiro" 
            element={<ProtectedRoute><Financeiro /></ProtectedRoute>} 
          />
          <Route 
            path="/TransacoesFinanceiras" 
            element={<ProtectedRoute><TransacoesFinanceiras /></ProtectedRoute>} 
          />
          <Route 
            path="/CategoriaTransacoes" 
            element={<ProtectedRoute><CategoriaTransacoes /></ProtectedRoute>} 
          />
          <Route 
            path="/ContasPagar" 
            element={<ProtectedRoute><ContasPagar /></ProtectedRoute>} 
          />
          <Route 
            path="/ContasReceber" 
            element={<ProtectedRoute><ContasReceber /></ProtectedRoute>} 
          />
          <Route 
            path="/FluxoCaixa" 
            element={<ProtectedRoute><FluxoCaixa /></ProtectedRoute>} 
          />
          <Route 
            path="/FinanceiroFuncionarios" 
            element={<ProtectedRoute><FinanceiroFuncionarios /></ProtectedRoute>} 
          />
          <Route 
            path="/Fornecedores" 
            element={<ProtectedRoute><Fornecedores /></ProtectedRoute>} 
          />
          <Route 
            path="/Equipamentos" 
            element={<ProtectedRoute><Equipamentos /></ProtectedRoute>} 
          />
          <Route 
            path="/Parceiros" 
            element={<ProtectedRoute><Parceiros /></ProtectedRoute>} 
          />
          <Route 
            path="/Usuarios" 
            element={<ProtectedRoute><Usuarios /></ProtectedRoute>} 
          />

          {/* Adicione outras rotas aqui */}
        </Routes>
      </Router>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
