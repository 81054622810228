import React, { useState, useEffect } from 'react';
import {
  Box, Button, Container, Typography, Grid, Paper, IconButton, Snackbar, Alert, CssBaseline, Toolbar, TextField, MenuItem, Modal
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialCashFlowState = {
  descricao: '',
  valor: '',
  tipo: 'Entrada',
  data: '',
  categoria: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default function FluxoCaixa() {
  const [cashFlows, setCashFlows] = useState([]);
  const [cashFlow, setCashFlow] = useState(initialCashFlowState);
  const [totalReceitas, setTotalReceitas] = useState(0);
  const [totalDespesas, setTotalDespesas] = useState(0);
  const [projecaoSaldo, setProjecaoSaldo] = useState(0);
  const [categoriaData, setCategoriaData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [tipoModal, setTipoModal] = useState(''); // 'Receita' ou 'Despesa'
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    fetchCashFlows();
  }, []);

  const fetchCashFlows = async () => {
    try {
      const response = await axios.get('https://api.aabbengenharia.com.br/api/financeiro/fluxocaixa');
      const data = response.data;
      setCashFlows(data);
      
      const receitas = data.filter(item => item.tipo === 'Entrada').reduce((acc, item) => acc + parseFloat(item.valor), 0);
      const despesas = data.filter(item => item.tipo === 'Saída').reduce((acc, item) => acc + parseFloat(item.valor), 0);
      setTotalReceitas(receitas);
      setTotalDespesas(despesas);
      setProjecaoSaldo(receitas - despesas);

      const categorias = {};
      data.forEach(item => {
        if (categorias[item.categoria]) {
          categorias[item.categoria] += parseFloat(item.valor);
        } else {
          categorias[item.categoria] = parseFloat(item.valor);
        }
      });
      setCategoriaData(Object.keys(categorias).map(categoria => ({
        name: categoria || 'Sem Categoria',
        value: categorias[categoria]
      })));
      
    } catch (error) {
      console.error('Erro ao buscar fluxo de caixa:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCashFlow({ ...cashFlow, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const cleanedCashFlow = {
        ...cashFlow,
        categoria: cashFlow.categoria || null,
        tipo: tipoModal === 'Receita' ? 'Entrada' : 'Saída', // Define se é receita ou despesa
      };

      if (isEditing) {
        await axios.put(`https://api.aabbengenharia.com.br/api/financeiro/fluxocaixa/${cashFlow.id}`, cleanedCashFlow);
        setSnackbarMessage('Fluxo de caixa atualizado com sucesso!');
      } else {
        await axios.post('https://api.aabbengenharia.com.br/api/financeiro/fluxocaixa', cleanedCashFlow);
        setSnackbarMessage('Fluxo de caixa cadastrado com sucesso!');
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setCashFlow(initialCashFlowState);
      setIsEditing(false);
      setOpen(false);
      fetchCashFlows();
    } catch (error) {
      setSnackbarMessage('Erro ao salvar fluxo de caixa!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao salvar fluxo de caixa:', error.response ? error.response.data : error.message);
    }
  };

  const openModal = (tipo) => {
    setTipoModal(tipo); // Define se o modal é para receita ou despesa
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCashFlow(initialCashFlowState);
    setIsEditing(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={() => setMenuOpen(!menuOpen)} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={() => setMenuOpen(!menuOpen)} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                {/* Resumo Receitas */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6">A Receber</Typography>
                    <Typography variant="h4" color="green">
                      R${totalReceitas.toFixed(2)}
                    </Typography>
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                      onClick={() => openModal('Receita')}
                    >
                      Adicionar receita
                    </Button>
                  </Paper>
                </Grid>
                {/* Resumo Despesas */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6">A Pagar</Typography>
                    <Typography variant="h4" color="red">
                      R${totalDespesas.toFixed(2)}
                    </Typography>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<AddIcon />}
                      onClick={() => openModal('Despesa')}
                    >
                      Adicionar despesa
                    </Button>
                  </Paper>
                </Grid>
                {/* Projeção de saldo */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6">Projeção de Saldo</Typography>
                    <Typography variant="h4">
                      R${projecaoSaldo.toFixed(2)}
                    </Typography>
                  </Paper>
                </Grid>
                {/* Gráfico por Categoria */}
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6">Por Categoria</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={categoriaData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          fill="#8884d8"
                          label
                        >
                          {categoriaData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        {/* Modal de Cadastro de Transações */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {tipoModal === 'Receita' ? 'Cadastrar Receita' : 'Cadastrar Despesa'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Descrição"
                    name="descricao"
                    value={cashFlow.descricao}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Valor"
                    name="valor"
                    type="number"
                    value={cashFlow.valor}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data"
                    name="data"
                    type="date"
                    value={cashFlow.data}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Categoria"
                    name="categoria"
                    value={cashFlow.categoria}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
