import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Container,
  Typography,
  Grid,
  Modal,
  Snackbar,
  Alert,
  Paper,
  IconButton,
  Toolbar,
  CssBaseline,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialParceiroState = {
  nome_razao_social: '',
  tipo_parceiro: '',
  cnpj_cpf: '',
  telefone: '',
  email: '',
  website: '',
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  descricao_parceria: '',
  observacoes: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

export default function Parceiros() {
  const [parceiros, setParceiros] = useState([]);
  const [parceiro, setParceiro] = useState(initialParceiroState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true); // Controlar abertura do menu lateral

  useEffect(() => {
    fetchParceiros();
  }, []);

  const fetchParceiros = async () => {
    try {
      const response = await axios.get('/api/parceiros');
      setParceiros(response.data);
    } catch (error) {
      console.error('Erro ao buscar parceiros:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParceiro({ ...parceiro, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axios.put(`/api/parceiros/${parceiro.id}`, parceiro);
        setSnackbarMessage('Parceiro atualizado com sucesso!');
      } else {
        await axios.post('/api/parceiros', parceiro);
        setSnackbarMessage('Parceiro cadastrado com sucesso!');
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setParceiro(initialParceiroState);
      setIsEditing(false);
      setOpen(false);
      fetchParceiros();
    } catch (error) {
      setSnackbarMessage('Erro ao salvar parceiro!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao salvar parceiro:', error);
    }
  };

  const handleEdit = (parceiro) => {
    setParceiro(parceiro);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/parceiros/${id}`);
      setSnackbarMessage('Parceiro deletado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchParceiros();
    } catch (error) {
      setSnackbarMessage('Erro ao deletar parceiro!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao deletar parceiro:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setParceiro(initialParceiroState);
    setIsEditing(false);
  };
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => setMenuOpen(!menuOpen); // Controlar abertura/fechamento do menu

  const columns = [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'nome_razao_social', header: 'Nome/Razão Social' },
    { accessorKey: 'tipo_parceiro', header: 'Tipo de Parceiro' },
    { accessorKey: 'cnpj_cpf', header: 'CNPJ/CPF' },
    { accessorKey: 'telefone', header: 'Telefone' },
    { accessorKey: 'email', header: 'Email' },
    { accessorKey: 'descricao_parceria', header: 'Descrição da Parceria' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} /> {/* Adicionando o Header */}
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} /> {/* Adicionando o Menu lateral */}
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de Parceiros
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Novo Parceiro
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={parceiros}
                  enableColumnOrdering
                  enablePagination
                  enableSorting
                />
              </Paper>
            </Container>
          </Box>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Parceiro' : 'Cadastrar Parceiro'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome/Razão Social"
                    name="nome_razao_social"
                    value={parceiro.nome_razao_social}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Tipo de Parceiro"
                    name="tipo_parceiro"
                    value={parceiro.tipo_parceiro}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Fornecedor">Fornecedor</MenuItem>
                    <MenuItem value="Prestador de Serviços">Prestador de Serviços</MenuItem>
                    <MenuItem value="Outro">Outro</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CNPJ/CPF"
                    name="cnpj_cpf"
                    value={parceiro.cnpj_cpf}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Telefone"
                    name="telefone"
                    value={parceiro.telefone}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={parceiro.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Website"
                    name="website"
                    value={parceiro.website}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CEP"
                    name="cep"
                    value={parceiro.cep}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Endereço"
                    name="endereco"
                    value={parceiro.endereco}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Número"
                    name="numero"
                    value={parceiro.numero}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    name="complemento"
                    value={parceiro.complemento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    name="bairro"
                    value={parceiro.bairro}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="cidade"
                    value={parceiro.cidade}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Estado"
                    name="estado"
                    value={parceiro.estado}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descrição da Parceria"
                    name="descricao_parceria"
                    value={parceiro.descricao_parceria}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Observações"
                    name="observacoes"
                    value={parceiro.observacoes}
                    onChange={handleChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
