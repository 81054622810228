import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, MenuItem, Container, Typography,
  Grid, Modal, IconButton, Snackbar, Alert, CssBaseline, Toolbar, Paper, Tabs, Tab,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialBeneficioState = {
  tipo_beneficio: '',
  valor: '',
  descricao: '',
};

const initialPagamentoState = {
  descricao: '',
  valor: '',
  data_pagamento: '',
};

const initialSalarioState = {
  valor: '',
  data_pagamento: '',
  descricao: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

export default function FinanceiroFuncionario() {
  const [beneficios, setBeneficios] = useState([]);
  const [pagamentos, setPagamentos] = useState([]);
  const [salarios, setSalarios] = useState([]);
  const [beneficio, setBeneficio] = useState(initialBeneficioState);
  const [pagamento, setPagamento] = useState(initialPagamentoState);
  const [salario, setSalario] = useState(initialSalarioState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    // Fetch data when component mounts
    fetchBeneficios();
    fetchPagamentos();
    fetchSalarios();
  }, []);

  const fetchBeneficios = async () => {
    try {
      const response = await axios.get('https://api.aabbengenharia.com.br/api/funcionarios/beneficios');
      setBeneficios(response.data);
    } catch (error) {
      console.error('Erro ao buscar benefícios:', error);
    }
  };

  const fetchPagamentos = async () => {
    try {
      const response = await axios.get('https://api.aabbengenharia.com.br/api/funcionarios/pagamentos');
      setPagamentos(response.data);
    } catch (error) {
      console.error('Erro ao buscar pagamentos:', error);
    }
  };

  const fetchSalarios = async () => {
    try {
      const response = await axios.get('https://api.aabbengenharia.com.br/api/funcionarios/salarios');
      setSalarios(response.data);
    } catch (error) {
      console.error('Erro ao buscar salários:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleChange = (e, setState) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e, state, setState, url, successMessage) => {
    e.preventDefault();

    try {
      if (isEditing) {
        await axios.put(`${url}/${state.id}`, state);
        setSnackbarMessage(`${successMessage} atualizado com sucesso!`);
      } else {
        await axios.post(url, state);
        setSnackbarMessage(`${successMessage} cadastrado com sucesso!`);
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setState(initialBeneficioState);
      setIsEditing(false);
      setOpen(false);
      fetchBeneficios();
      fetchPagamentos();
      fetchSalarios();
    } catch (error) {
      setSnackbarMessage(`Erro ao salvar ${successMessage.toLowerCase()}!`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error(`Erro ao salvar ${successMessage.toLowerCase()}:`, error.response ? error.response.data : error.message);
    }
  };

  const handleEdit = (state, setState, data) => {
    setState(data);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id, url, successMessage) => {
    try {
      await axios.delete(`${url}/${id}`);
      setSnackbarMessage(`${successMessage} deletado com sucesso!`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchBeneficios();
      fetchPagamentos();
      fetchSalarios();
    } catch (error) {
      setSnackbarMessage(`Erro ao deletar ${successMessage.toLowerCase()}!`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error(`Erro ao deletar ${successMessage.toLowerCase()}:`, error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setBeneficio(initialBeneficioState);
    setPagamento(initialPagamentoState);
    setSalario(initialSalarioState);
    setIsEditing(false);
  };
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  const columnsBeneficios = [
    { accessorKey: 'tipo_beneficio', header: 'Tipo de Benefício' },
    { accessorKey: 'valor', header: 'Valor' },
    { accessorKey: 'descricao', header: 'Descrição' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleEdit(beneficio, setBeneficio, row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id, 'https://api.aabbengenharia.com.br/api/funcionarios/beneficios', 'Benefício')}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const columnsPagamentos = [
    { accessorKey: 'descricao', header: 'Descrição' },
    { accessorKey: 'valor', header: 'Valor' },
    { accessorKey: 'data_pagamento', header: 'Data de Pagamento' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleEdit(pagamento, setPagamento, row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id, 'https://api.aabbengenharia.com.br/api/funcionarios/pagamentos', 'Pagamento')}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const columnsSalarios = [
    { accessorKey: 'valor', header: 'Valor' },
    { accessorKey: 'data_pagamento', header: 'Data de Pagamento' },
    { accessorKey: 'descricao', header: 'Descrição' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleEdit(salario, setSalario, row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id, 'https://api.aabbengenharia.com.br/api/funcionarios/salarios', 'Salário')}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Financeiro dos Funcionários
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Nova Entrada
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Tabs de detalhes">
                  <Tab label="Benefícios" />
                  <Tab label="Pagamentos" />
                  <Tab label="Salários" />
                </Tabs>
                <Box sx={{ mt: 2 }}>
                  {tabIndex === 0 && (
                    <MaterialReactTable
                      columns={columnsBeneficios}
                      data={beneficios}
                      enableColumnOrdering
                      enableRowSelection
                      enablePagination
                      enableSorting
                      muiTableBodyRowProps={{ hover: true }}
                      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                      pageSizeOptions={[10, 25, 50]}
                      initialState={{ pagination: { pageSize: 50 } }}
                    />
                  )}
                  {tabIndex === 1 && (
                    <MaterialReactTable
                      columns={columnsPagamentos}
                      data={pagamentos}
                      enableColumnOrdering
                      enableRowSelection
                      enablePagination
                      enableSorting
                      muiTableBodyRowProps={{ hover: true }}
                      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                      pageSizeOptions={[10, 25, 50]}
                      initialState={{ pagination: { pageSize: 50 } }}
                    />
                  )}
                  {tabIndex === 2 && (
                    <MaterialReactTable
                      columns={columnsSalarios}
                      data={salarios}
                      enableColumnOrdering
                      enableRowSelection
                      enablePagination
                      enableSorting
                      muiTableBodyRowProps={{ hover: true }}
                      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                      pageSizeOptions={[10, 25, 50]}
                      initialState={{ pagination: { pageSize: 50 } }}
                    />
                  )}
                </Box>
              </Paper>
            </Container>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar' : 'Cadastrar'} {tabIndex === 0 ? 'Benefício' : tabIndex === 1 ? 'Pagamento' : 'Salário'}
            </Typography>
            <form onSubmit={(e) => handleSubmit(e, tabIndex === 0 ? beneficio : tabIndex === 1 ? pagamento : salario, tabIndex === 0 ? setBeneficio : tabIndex === 1 ? setPagamento : setSalario, tabIndex === 0 ? 'https://api.aabbengenharia.com.br/api/funcionarios/beneficios' : tabIndex === 1 ? 'https://api.aabbengenharia.com.br/api/funcionarios/pagamentos' : 'https://api.aabbengenharia.com.br/api/funcionarios/salarios', tabIndex === 0 ? 'Benefício' : tabIndex === 1 ? 'Pagamento' : 'Salário')}>
              <Grid container spacing={2}>
                {tabIndex === 0 && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Tipo de Benefício"
                        name="tipo_beneficio"
                        value={beneficio.tipo_beneficio}
                        onChange={(e) => handleChange(e, setBeneficio)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Valor"
                        name="valor"
                        type="number"
                        value={beneficio.valor}
                        onChange={(e) => handleChange(e, setBeneficio)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Descrição"
                        name="descricao"
                        value={beneficio.descricao}
                        onChange={(e) => handleChange(e, setBeneficio)}
                      />
                    </Grid>
                  </>
                )}
                {tabIndex === 1 && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Descrição"
                        name="descricao"
                        value={pagamento.descricao}
                        onChange={(e) => handleChange(e, setPagamento)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Valor"
                        name="valor"
                        type="number"
                        value={pagamento.valor}
                        onChange={(e) => handleChange(e, setPagamento)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Data de Pagamento"
                        name="data_pagamento"
                        type="date"
                        value={pagamento.data_pagamento}
                        onChange={(e) => handleChange(e, setPagamento)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </>
                )}
                {tabIndex === 2 && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Valor"
                        name="valor"
                        type="number"
                        value={salario.valor}
                        onChange={(e) => handleChange(e, setSalario)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Data de Pagamento"
                        name="data_pagamento"
                        type="date"
                        value={salario.data_pagamento}
                        onChange={(e) => handleChange(e, setSalario)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Descrição"
                        name="descricao"
                        value={salario.descricao}
                        onChange={(e) => handleChange(e, setSalario)}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
