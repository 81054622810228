import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  // Verifica se o token de autenticação existe
  const token = localStorage.getItem('token');

  // Se o token não existir, redireciona para a página de login
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // Caso o token exista, renderiza o componente filho (a página protegida)
  return children;
};

export default ProtectedRoute;
