import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Container,
  Typography,
  Grid,
  Modal,
  IconButton,
  Snackbar,
  Alert,
  CssBaseline,
  Toolbar,
  Paper,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialServicoState = {
  codigo_servico: '',
  nome_servico: '',
  descricao_servico: '',
  categoria: '',
  subcategoria: '',
  unidade_medida: '',
  duracao_estimada: '',
  custo_servico: '',
  preco_venda: '',
  fornecedor_principal: '',
  custo_fornecedor: '',
  documentos_necessarios: '',
  recursos_necessarios: '',
  observacoes: '',
  status_servico: 'Ativo',
  codigo_fiscal_servico: '',
  cst: '',
  cfop: '',
  aliquota_iss: '',
  retencao_tributos: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

export default function Servicos() {
  const [servicos, setServicos] = useState([]);
  const [servico, setServico] = useState(initialServicoState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    fetchServicos();
  }, []);

  const fetchServicos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/servicos`);
      setServicos(response.data);
    } catch (error) {
      console.error('Erro ao buscar serviços:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setServico({ ...servico, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if (!servico.codigo_servico || !servico.nome_servico) {
        setSnackbarMessage('Código e Nome do Serviço são obrigatórios!');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
  
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/servicos/${servico.id}`, servico);
        setSnackbarMessage('Serviço atualizado com sucesso!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/servicos`, servico);
        setSnackbarMessage('Serviço cadastrado com sucesso!');
      }
  
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setServico(initialServicoState);
      setIsEditing(false);
      setOpen(false);
      fetchServicos();
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Erro ao salvar serviço!';
      setSnackbarMessage(errorMsg);  // Exibe o erro detalhado
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao salvar serviço:', error); // Logar o erro no console para depuração
    }
  };
  

  const handleEdit = (servico) => {
    setServico(servico);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/servicos/${id}`);
      setSnackbarMessage('Serviço deletado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchServicos();
    } catch (error) {
      setSnackbarMessage('Erro ao deletar serviço!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao deletar serviço:', error);
    }
  };

  const handleView = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/servicos/${id}`);
      setServico(response.data);
      setViewOpen(true);
    } catch (error) {
      console.error('Erro ao visualizar serviço:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setServico(initialServicoState);
    setIsEditing(false);
  };
  const handleViewClose = () => setViewOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  const columns = [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'codigo_servico', header: 'Código do Serviço' },
    { accessorKey: 'nome_servico', header: 'Nome do Serviço' },
    { accessorKey: 'categoria', header: 'Categoria' },
    { accessorKey: 'preco_venda', header: 'Preço de Venda' },
    { accessorKey: 'status_servico', header: 'Status' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleView(row.original.id)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de Serviços
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Novo Serviço
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={servicos}
                  enableColumnOrdering
                  enableRowSelection
                  enablePagination
                  enableSorting
                  muiTableBodyRowProps={{ hover: true }}
                  muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                  pageSizeOptions={[10, 25, 50]}
                  initialState={{ pagination: { pageSize: 50 } }}
                />
              </Paper>
            </Container>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Serviço' : 'Cadastrar Serviço'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Código do Serviço"
                    name="codigo_servico"
                    value={servico.codigo_servico}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Nome do Serviço"
                    name="nome_servico"
                    value={servico.nome_servico}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descrição do Serviço"
                    name="descricao_servico"
                    value={servico.descricao_servico}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Categoria"
                    name="categoria"
                    value={servico.categoria}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Subcategoria"
                    name="subcategoria"
                    value={servico.subcategoria}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Unidade de Medida"
                    name="unidade_medida"
                    value={servico.unidade_medida}
                    onChange={handleChange}
                  >
                    <MenuItem value="Unidade">Unidade</MenuItem>
                    <MenuItem value="Hora">Hora</MenuItem>
                    <MenuItem value="Dia">Dia</MenuItem>
                    <MenuItem value="Metro Quadrado">Metro Quadrado</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Duração Estimada"
                    name="duracao_estimada"
                    value={servico.duracao_estimada}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Custo do Serviço"
                    name="custo_servico"
                    value={servico.custo_servico}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preço de Venda"
                    name="preco_venda"
                    value={servico.preco_venda}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Fornecedor Principal"
                    name="fornecedor_principal"
                    value={servico.fornecedor_principal}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Custo do Fornecedor"
                    name="custo_fornecedor"
                    value={servico.custo_fornecedor}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Código Fiscal do Serviço"
                    name="codigo_fiscal_servico"
                    value={servico.codigo_fiscal_servico}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CST"
                    name="cst"
                    value={servico.cst}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CFOP"
                    name="cfop"
                    value={servico.cfop}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Alíquota de ISS"
                    name="aliquota_iss"
                    value={servico.aliquota_iss}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Documentos Necessários"
                    name="documentos_necessarios"
                    value={servico.documentos_necessarios}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Recursos Necessários"
                    name="recursos_necessarios"
                    value={servico.recursos_necessarios}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Observações"
                    name="observacoes"
                    value={servico.observacoes}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Status do Serviço"
                    name="status_servico"
                    value={servico.status_servico}
                    onChange={handleChange}
                  >
                    <MenuItem value="Ativo">Ativo</MenuItem>
                    <MenuItem value="Inativo">Inativo</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Retenção de Tributos"
                    name="retencao_tributos"
                    value={servico.retencao_tributos}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
        <Modal
          open={viewOpen}
          onClose={handleViewClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleViewClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Visualizar Serviço
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Código do Serviço:</strong> {servico.codigo_servico}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Nome do Serviço:</strong> {servico.nome_servico}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1">
                    <strong>Descrição do Serviço:</strong> {servico.descricao_servico}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Categoria:</strong> {servico.categoria}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Subcategoria:</strong> {servico.subcategoria}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Unidade de Medida:</strong> {servico.unidade_medida}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Duração Estimada:</strong> {servico.duracao_estimada}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Custo do Serviço:</strong> {servico.custo_servico}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Preço de Venda:</strong> {servico.preco_venda}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Fornecedor Principal:</strong> {servico.fornecedor_principal}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Custo do Fornecedor:</strong> {servico.custo_fornecedor}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Código Fiscal do Serviço:</strong> {servico.codigo_fiscal_servico}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>CST:</strong> {servico.cst}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>CFOP:</strong> {servico.cfop}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Alíquota de ISS:</strong> {servico.aliquota_iss}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <strong>Documentos Necessários:</strong> {servico.documentos_necessarios}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <strong>Recursos Necessários:</strong> {servico.recursos_necessarios}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <strong>Observações:</strong> {servico.observacoes}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Status do Serviço:</strong> {servico.status_servico}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <strong>Retenção de Tributos:</strong> {servico.retencao_tributos}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
