import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, MenuItem, Container, Typography,
  Grid, Modal, Snackbar, Alert, Paper, IconButton, Toolbar, CssBaseline,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialAccountState = {
  descricao: '',
  valor: '',
  data_vencimento: '',
  data_pagamento: '',
  status_pagamento: 'Pendente',
  empresa_id: '', // Campo para o ID da empresa selecionada
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

// Lista de empresas disponíveis (simulado)
const empresas = [
  { id: 1, nome: 'Aabb Engenharia' },
  { id: 2, nome: 'Chalé' },
  { id: 3, nome: 'Concreto' },
];

export default function ContasPagar() {
  const [contas, setContas] = useState([]);
  const [conta, setConta] = useState(initialAccountState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    fetchContas();
  }, []);

  const fetchContas = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/financeiro/contas_pagar`);
      setContas(response.data);
    } catch (error) {
      console.error('Erro ao buscar contas:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConta({ ...conta, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/financeiro/contas_pagar/${conta.id}`, conta);
        setSnackbarMessage('Conta atualizada com sucesso!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/financeiro/contas_pagar`, conta);
        setSnackbarMessage('Conta cadastrada com sucesso!');
      }
  
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setConta(initialAccountState);
      setIsEditing(false);
      setOpen(false);
      fetchContas();
    } catch (error) {
      setSnackbarMessage('Erro ao salvar conta!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
  
      // Logs detalhados
      console.error('Erro completo:', error);  // Log completo do erro
  
      if (error.response) {
        // O backend respondeu com status de erro
        console.error('Erro do backend:', error.response.data);
      } else if (error.request) {
        // O request foi feito mas não houve resposta
        console.error('Sem resposta do backend:', error.request);
      } else {
        // Outro erro ocorreu durante a configuração da requisição
        console.error('Erro na requisição:', error.message);
      }
    }
  };
  
  

  const handleEdit = (conta) => {
    setConta(conta);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/financeiro/contas_pagar/${id}`);
      setSnackbarMessage('Conta deletada com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchContas();
    } catch (error) {
      setSnackbarMessage('Erro ao deletar conta!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao deletar conta:', error);
    }
  };

  const handleView = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/financeiro/contas_pagar/${id}`);
      setConta(response.data);
      setViewOpen(true);
    } catch (error) {
      console.error('Erro ao visualizar conta:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setConta(initialAccountState);
    setIsEditing(false);
  };
  const handleViewClose = () => setViewOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  const columns = [
    { accessorKey: 'descricao', header: 'Descrição' },
    { accessorKey: 'valor', header: 'Valor' },
    { accessorKey: 'data_vencimento', header: 'Data de Vencimento' },
    { accessorKey: 'data_pagamento', header: 'Data de Pagamento' },
    { accessorKey: 'status_pagamento', header: 'Status' },
    { accessorKey: 'empresa_id', header: 'Empresa' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleView(row.original.id)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Contas a Pagar
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Nova Conta
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={contas}
                  enableColumnOrdering
                  enableRowSelection
                  enablePagination
                  enableSorting
                  muiTableBodyRowProps={{ hover: true }}
                  muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                  pageSizeOptions={[10, 25, 50]}
                  initialState={{ pagination: { pageSize: 50 } }}
                />
              </Paper>
            </Container>
          </Box>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Conta' : 'Cadastrar Conta'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Descrição"
                    name="descricao"
                    value={conta.descricao}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Valor"
                    name="valor"
                    type="number"
                    value={conta.valor}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data de Vencimento"
                    name="data_vencimento"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={conta.data_vencimento}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data de Pagamento"
                    name="data_pagamento"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={conta.data_pagamento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Status"
                    name="status_pagamento"
                    value={conta.status_pagamento}
                    onChange={handleChange}
                  >
                    <MenuItem value="Pendente">Pendente</MenuItem>
                    <MenuItem value="Pago">Pago</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Empresa"
                    name="empresa_id"
                    value={conta.empresa_id}
                    onChange={handleChange}
                    required
                  >
                    {empresas.map((empresa) => (
                      <MenuItem key={empresa.id} value={empresa.id}>
                        {empresa.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>

        <Modal
          open={viewOpen}
          onClose={handleViewClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleViewClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Visualizar Conta
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Descrição:</strong> {conta.descricao}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Valor:</strong> {conta.valor}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Data de Vencimento:</strong> {conta.data_vencimento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Data de Pagamento:</strong> {conta.data_pagamento || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Status:</strong> {conta.status_pagamento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Empresa:</strong> {conta.empresa_id}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
