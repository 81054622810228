import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#7a7a7a', // Cor harmonizada com o restante do sistema
  color: '#ffffff', // Texto branco para melhor contraste
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header({ open, toggleDrawer }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userName, setUserName] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  const [userProfile, setUserProfile] = React.useState(''); // Perfil do usuário
  const [currentTime, setCurrentTime] = React.useState('');
  const [connectedTime, setConnectedTime] = React.useState(0);
  const [pageTitle, setPageTitle] = React.useState(document.title);
  const [showModal, setShowModal] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setUserName(data.nome || data.email);  // Usar nome ou email como fallback
            setUserEmail(data.email);
            setUserProfile(data.perfil); // Perfil do usuário
            console.log('Perfil do usuário:', data.perfil);  // Verifique o valor de perfil aqui
          } else {
            console.error('Erro ao buscar detalhes do usuário');
          }
        } catch (error) {
          console.error('Erro durante a requisição', error);
        }
      }
    };

    fetchUserDetails();

    const loginTime = localStorage.getItem('loginTime');
    const updateConnectedTime = () => {
      if (loginTime) {
        const now = Date.now();
        const elapsedSeconds = Math.floor((now - loginTime) / 1000);
        setConnectedTime(elapsedSeconds);
      }
    };

    const updateTime = () => {
      const now = new Date();
      const options = { timeZone: 'America/Cuiaba', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      const timeString = now.toLocaleTimeString('pt-BR', options);
      setCurrentTime(timeString);
    };

    updateConnectedTime();
    updateTime();

    const timeInterval = setInterval(updateTime, 1000);
    const connectedTimeInterval = setInterval(updateConnectedTime, 1000);

    const inactivityTimer = setTimeout(() => {
      setShowModal(true);
    }, 30 * 60 * 1000);

    return () => {
      clearInterval(timeInterval);
      clearInterval(connectedTimeInterval);
      clearTimeout(inactivityTimer);
    };
  }, []);

  const formatConnectedTime = () => {
    const hours = Math.floor(connectedTime / 3600);
    const minutes = Math.floor((connectedTime % 3600) / 60);
    const seconds = connectedTime % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loginTime');
    navigate('/login');
  };

  const handleModalClose = () => {
    setShowModal(false);
    const inactivityTimer = setTimeout(() => {
      setShowModal(true);
    }, 30 * 60 * 1000);
  };

  const handleSessionEnd = () => {
    setShowModal(false);
    handleLogout();
  };

  React.useEffect(() => {
    let sessionTimeout;
    if (showModal) {
      sessionTimeout = setTimeout(handleSessionEnd, 40 * 1000);
    }
    return () => clearTimeout(sessionTimeout);
  }, [showModal]);

  return (
    <>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
              component="h1"
              variant="h6"
              noWrap
              sx={{ fontFamily: 'Poppins', fontSize: '1rem' }} // Nome da aplicação com a fonte Poppins
            >
              {pageTitle}
            </Typography>
            <Typography
              component="p"
              variant="body2"
              noWrap
              sx={{ fontSize: '0.75rem', fontFamily: 'Poppins' }} // Hora atual com a fonte Poppins
            >
              Hora atual (Cuiabá): {currentTime}
            </Typography>
            <Typography
              component="p"
              variant="body2"
              noWrap
              sx={{ fontSize: '0.75rem', fontFamily: 'Poppins' }} // Tempo conectado com a fonte Poppins
            >
              Tempo conectado: {formatConnectedTime()}
            </Typography>
          </Box>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon sx={{ color: 'orange' }} />
            </Badge>
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
            <Typography variant="body1" color="inherit" sx={{ marginRight: 1, fontFamily: 'Poppins' }}>
              {userName || userEmail} {/* Exibir o nome do usuário ou o email como fallback */}
            </Typography>
            <IconButton color="inherit" onClick={handleAvatarClick}>
              <Avatar>
                {userName ? userName.charAt(0).toUpperCase() : userEmail.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Meu Perfil</MenuItem>
            {/* Renderizar "Minhas Empresas" apenas se o perfil do usuário for 'superadmin' */}
            {userProfile && userProfile === 'superadmin' && (
              <MenuItem onClick={handleClose}>Minhas Empresas</MenuItem>
            )}
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Modal
        open={showModal}
        onClose={handleSessionEnd}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Você ainda está aí?
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            Sua sessão será encerrada em 40 segundos.
          </Typography>
          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Button variant="contained" onClick={handleModalClose}>
              Sim, estou aqui
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
