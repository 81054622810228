import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Container,
  Typography,
  Grid,
  Modal,
  IconButton,
  Snackbar,
  Alert,
  CssBaseline,
  Toolbar,
  Paper,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialFuncionarioState = {
  nome_completo: '',
  cpf: '',
  rg: '',
  data_nascimento: '',
  sexo: '',
  estado_civil: '',
  nacionalidade: '',
  naturalidade: '',
  telefone_fixo: '',
  celular: '',
  email: '',
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  cargo: '',
  departamento: '',
  data_admissao: '',
  salario: '',
  tipo_contrato: '',
  carga_horaria: '',
  supervisor: '',
  banco: '',
  agencia: '',
  conta_corrente: '',
  tipo_conta: '',
  ctps: '',
  pis: '',
  certificado_reservista: '',
  cnh: '',
  plano_saude: '',
  vale_refeicao: '',
  vale_transporte: '',
  outros_beneficios: '',
};




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

export default function Funcionarios() {
  const [funcionarios, setFuncionarios] = useState([]);
  const [funcionario, setFuncionario] = useState(initialFuncionarioState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    fetchFuncionarios();
  }, []);

  const API_BASE_URL = process.env.REACT_APP_API_URL;



  const fetchFuncionarios = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/funcionarios`);  // Verifique se está no plural

      console.log('Funcionários recebidos:', response.data);
      setFuncionarios(response.data);
    } catch (error) {
      console.error('Erro ao buscar funcionários:', error.response?.data || error.message || error);
      setSnackbarMessage('Erro ao buscar funcionários');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFuncionario({ ...funcionario, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Limpar espaços em branco de nome_completo e cpf
      const nome_completo = funcionario.nome_completo.trim();
      const cpf = funcionario.cpf.trim();
  
      // Garantir que Nome Completo e CPF estão preenchidos
      if (!nome_completo || !cpf) {
        setSnackbarMessage('Nome Completo e CPF são obrigatórios!');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
  
      // Limpar campos vazios e manter apenas Nome Completo e CPF obrigatórios
      const cleanedFuncionario = {
        nome_completo,
        cpf,
        rg: funcionario.rg || null,
        data_nascimento: funcionario.data_nascimento || null,
        sexo: funcionario.sexo || null,
        estado_civil: funcionario.estado_civil || null,
        nacionalidade: funcionario.nacionalidade || null,
        naturalidade: funcionario.naturalidade || null,
        telefone_fixo: funcionario.telefone_fixo || null,
        celular: funcionario.celular || null,
        email: funcionario.email || null,
        cep: funcionario.cep || null,
        endereco: funcionario.endereco || null,
        numero: funcionario.numero || null,
        complemento: funcionario.complemento || null,
        bairro: funcionario.bairro || null,
        cidade: funcionario.cidade || null,
        estado: funcionario.estado || null,
        cargo: funcionario.cargo || null,
        departamento: funcionario.departamento || null,
        data_admissao: funcionario.data_admissao || null,
        salario: funcionario.salario || null,
        tipo_contrato: funcionario.tipo_contrato || null,
        carga_horaria: funcionario.carga_horaria || null,
        supervisor: funcionario.supervisor || null,
        banco: funcionario.banco || null,
        agencia: funcionario.agencia || null,
        conta_corrente: funcionario.conta_corrente || null,
        tipo_conta: funcionario.tipo_conta || null,
        ctps: funcionario.ctps || null,
        pis: funcionario.pis || null,
        certificado_reservista: funcionario.certificado_reservista || null,
        cnh: funcionario.cnh || null,
        outros_documentos: funcionario.outros_documentos || null,
        observacoes: funcionario.observacoes || null,
        plano_saude: funcionario.plano_saude || null,
        vale_refeicao: funcionario.vale_refeicao || null,
        vale_transporte: funcionario.vale_transporte || null,
        outros_beneficios: funcionario.outros_beneficios || null,
      };
  
      // Desabilitar o botão de envio para evitar múltiplos cliques
      setOpen(false);
  
      if (isEditing) {
        // Atualizar funcionário existente
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/funcionarios/${funcionario.id}`,
          cleanedFuncionario
        );
        
        if (response.status === 200) {
          setSnackbarMessage('Funcionário atualizado com sucesso!');
        }
      } else {
        // Cadastrar novo funcionário
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/funcionarios`,
          
          cleanedFuncionario
        );
  
        if (response.status === 201) {
          setSnackbarMessage('Funcionário cadastrado com sucesso!');
        }
      }
  
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setFuncionario(initialFuncionarioState);
      setIsEditing(false);
      fetchFuncionarios(); // Recarregar lista de funcionários
    } catch (error) {
      // Tratar erros
      const errorMessage = error.response?.data?.message || 'Erro ao salvar funcionário!';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao salvar funcionário:', error);
    }
  };
  
  
      
  const handleEdit = (funcionario) => {
    setFuncionario({
      ...initialFuncionarioState,  // Garantir que campos vazios sejam tratados
      ...funcionario,
    });
    setIsEditing(true);
    setOpen(true);
  };
  
      
      const handleDelete = async (id) => {
        try {
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/funcionarios/${id}`
          );
      
          setSnackbarMessage('Funcionário deletado com sucesso!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          fetchFuncionarios();
        } catch (error) {
          setSnackbarMessage('Erro ao deletar funcionário!');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          console.error('Erro ao deletar funcionário:', error);
        }
      };
      
      const handleView = async (id) => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/funcionarios/${id}`
          );
          setFuncionario(response.data);
          setViewOpen(true);
        } catch (error) {
          console.error('Erro ao visualizar funcionário:', error);
        }
      };
      
      const handleOpen = () => setOpen(true);
      const handleClose = () => {
        setOpen(false);
        setFuncionario(initialFuncionarioState);
        setIsEditing(false);
      };
      
      const handleViewClose = () => setViewOpen(false);
      const handleSnackbarClose = () => setSnackbarOpen(false);
      const toggleDrawer = () => {
        setMenuOpen(!menuOpen);
      };
      

      const columns = [
        { accessorKey: 'id', header: 'ID' },
        { accessorKey: 'nome_completo', header: 'Nome Completo' },
        { accessorKey: 'cpf', header: 'CPF' },
        { accessorKey: 'cargo', header: 'Cargo' },
        { accessorKey: 'departamento', header: 'Departamento' },
        { accessorKey: 'email', header: 'Email' },
        {
          accessorKey: 'actions',
          header: 'Ações',
          Cell: ({ row }) => (
            <div>
              <IconButton onClick={() => handleView(row.original.id)}>
                <VisibilityIcon />
              </IconButton>
              <IconButton onClick={() => handleEdit(row.original)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(row.original.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        },
      ];
      

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de Funcionários
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Novo Funcionário
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={funcionarios}
                  enableColumnOrdering
                  enableRowSelection
                  enablePagination
                  enableSorting
                  muiTableBodyRowProps={{ hover: true }}
                  muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                  pageSizeOptions={[10, 25, 50]}
                  initialState={{ pagination: { pageSize: 50 } }}
                />
              </Paper>
            </Container>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Funcionário' : 'Cadastrar Funcionário'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome Completo"
                    name="nome_completo"
                    value={funcionario.nome_completo}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CPF"
                    name="cpf"
                    value={funcionario.cpf}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="RG"
                    name="rg"
                    value={funcionario.rg}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    fullWidth
                    label="Data de Nascimento"
                    name="data_nascimento"
                    InputLabelProps={{ shrink: true }}
                    value={funcionario.data_nascimento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Sexo"
                    name="sexo"
                    value={funcionario.sexo}
                    onChange={handleChange}
                  >
                    <MenuItem value="Masculino">Masculino</MenuItem>
                    <MenuItem value="Feminino">Feminino</MenuItem>
                    <MenuItem value="Outro">Outro</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Estado Civil"
                    name="estado_civil"
                    value={funcionario.estado_civil}
                    onChange={handleChange}
                  >
                    <MenuItem value="Solteiro">Solteiro</MenuItem>
                    <MenuItem value="Casado">Casado</MenuItem>
                    <MenuItem value="Divorciado">Divorciado</MenuItem>
                    <MenuItem value="Viúvo">Viúvo</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nacionalidade"
                    name="nacionalidade"
                    value={funcionario.nacionalidade}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Naturalidade"
                    name="naturalidade"
                    value={funcionario.naturalidade}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Telefone Fixo"
                    name="telefone_fixo"
                    value={funcionario.telefone_fixo}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Celular"
                    name="celular"
                    value={funcionario.celular}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={funcionario.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CEP"
                    name="cep"
                    value={funcionario.cep}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Endereço"
                    name="endereco"
                    value={funcionario.endereco}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Número"
                    name="numero"
                    value={funcionario.numero}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    name="complemento"
                    value={funcionario.complemento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    name="bairro"
                    value={funcionario.bairro}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="cidade"
                    value={funcionario.cidade}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Estado"
                    name="estado"
                    value={funcionario.estado}
                    onChange={handleChange}
                  >
                    <MenuItem value="AC">Acre</MenuItem>
                    <MenuItem value="AL">Alagoas</MenuItem>
                    <MenuItem value="AP">Amapá</MenuItem>
                    <MenuItem value="AM">Amazonas</MenuItem>
                    <MenuItem value="BA">Bahia</MenuItem>
                    <MenuItem value="CE">Ceará</MenuItem>
                    <MenuItem value="DF">Distrito Federal</MenuItem>
                    <MenuItem value="ES">Espírito Santo</MenuItem>
                    <MenuItem value="GO">Goiás</MenuItem>
                    <MenuItem value="MA">Maranhão</MenuItem>
                    <MenuItem value="MT">Mato Grosso</MenuItem>
                    <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                    <MenuItem value="MG">Minas Gerais</MenuItem>
                    <MenuItem value="PA">Pará</MenuItem>
                    <MenuItem value="PB">Paraíba</MenuItem>
                    <MenuItem value="PR">Paraná</MenuItem>
                    <MenuItem value="PE">Pernambuco</MenuItem>
                    <MenuItem value="PI">Piauí</MenuItem>
                    <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                    <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                    <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                    <MenuItem value="RO">Rondônia</MenuItem>
                    <MenuItem value="RR">Roraima</MenuItem>
                    <MenuItem value="SC">Santa Catarina</MenuItem>
                    <MenuItem value="SP">São Paulo</MenuItem>
                    <MenuItem value="SE">Sergipe</MenuItem>
                    <MenuItem value="TO">Tocantins</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cargo"
                    name="cargo"
                    value={funcionario.cargo}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Departamento"
                    name="departamento"
                    value={funcionario.departamento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    fullWidth
                    label="Data de Admissão"
                    name="data_admissao"
                    InputLabelProps={{ shrink: true }}
                    value={funcionario.data_admissao}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Salário"
                    name="salario"
                    value={funcionario.salario}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Tipo de Contrato"
                    name="tipo_contrato"
                    value={funcionario.tipo_contrato}
                    onChange={handleChange}
                  >
                    <MenuItem value="CLT">CLT</MenuItem>
                    <MenuItem value="Temporário">Temporário</MenuItem>
                    <MenuItem value="Estágio">Estágio</MenuItem>
                    <MenuItem value="Outro">Outro</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Carga Horária"
                    name="carga_horaria"
                    value={funcionario.carga_horaria}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Supervisor"
                    name="supervisor"
                    value={funcionario.supervisor}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Banco"
                    name="banco"
                    value={funcionario.banco}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Agência"
                    name="agencia"
                    value={funcionario.agencia}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Conta Corrente"
                    name="conta_corrente"
                    value={funcionario.conta_corrente}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Tipo de Conta"
                    name="tipo_conta"
                    value={funcionario.tipo_conta}
                    onChange={handleChange}
                  >
                    <MenuItem value="Conta Corrente">Conta Corrente</MenuItem>
                    <MenuItem value="Conta Poupança">Conta Poupança</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CTPS"
                    name="ctps"
                    value={funcionario.ctps}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="PIS"
                    name="pis"
                    value={funcionario.pis}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Certificado Reservista"
                    name="certificado_reservista"
                    value={funcionario.certificado_reservista}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CNH"
                    name="cnh"
                    value={funcionario.cnh}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Outros Documentos"
                    name="outros_documentos"
                    value={funcionario.outros_documentos}
                    onChange={handleChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Observações"
                    name="observacoes"
                    value={funcionario.observacoes}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Plano de Saúde"
                    name="plano_saude"
                    value={funcionario.plano_saude}
                    onChange={handleChange}
                  >
                    <MenuItem value="Sim">Sim</MenuItem>
                    <MenuItem value="Não">Não</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Vale Refeição"
                    name="vale_refeicao"
                    value={funcionario.vale_refeicao}
                    onChange={handleChange}
                  >
                    <MenuItem value="Sim">Sim</MenuItem>
                    <MenuItem value="Não">Não</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Vale Transporte"
                    name="vale_transporte"
                    value={funcionario.vale_transporte}
                    onChange={handleChange}
                  >
                    <MenuItem value="Sim">Sim</MenuItem>
                    <MenuItem value="Não">Não</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Outros Benefícios"
                    name="outros_beneficios"
                    value={funcionario.outros_beneficios}
                    onChange={handleChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
        <Modal
          open={viewOpen}
          onClose={handleViewClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleViewClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Visualizar Funcionário
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Nome Completo:</strong> {funcionario.nome_completo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>CPF:</strong> {funcionario.cpf}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>RG:</strong> {funcionario.rg}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Data de Nascimento:</strong>{' '}
                    {funcionario.data_nascimento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Sexo:</strong> {funcionario.sexo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Estado Civil:</strong> {funcionario.estado_civil}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Nacionalidade:</strong> {funcionario.nacionalidade}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Naturalidade:</strong> {funcionario.naturalidade}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Telefone Fixo:</strong> {funcionario.telefone_fixo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Celular:</strong> {funcionario.celular}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Email:</strong> {funcionario.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>CEP:</strong> {funcionario.cep}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Endereço:</strong> {funcionario.endereco}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Número:</strong> {funcionario.numero}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Complemento:</strong> {funcionario.complemento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Bairro:</strong> {funcionario.bairro}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Cidade:</strong> {funcionario.cidade}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Estado:</strong> {funcionario.estado}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Cargo:</strong> {funcionario.cargo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Departamento:</strong> {funcionario.departamento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Data de Admissão:</strong> {funcionario.data_admissao}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Salário:</strong> {funcionario.salario}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Tipo de Contrato:</strong> {funcionario.tipo_contrato}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Carga Horária:</strong> {funcionario.carga_horaria}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Supervisor:</strong> {funcionario.supervisor}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Banco:</strong> {funcionario.banco}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Agência:</strong> {funcionario.agencia}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Conta Corrente:</strong> {funcionario.conta_corrente}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Tipo de Conta:</strong> {funcionario.tipo_conta}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>CTPS:</strong> {funcionario.ctps}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>PIS:</strong> {funcionario.pis}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Certificado Reservista:</strong>{' '}
                    {funcionario.certificado_reservista}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>CNH:</strong> {funcionario.cnh}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1">
                    <strong>Outros Documentos:</strong>{' '}
                    {funcionario.outros_documentos}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1">
                    <strong>Observações:</strong> {funcionario.observacoes}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Plano de Saúde:</strong> {funcionario.plano_saude}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Vale Refeição:</strong> {funcionario.vale_refeicao}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Vale Transporte:</strong> {funcionario.vale_transporte}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1">
                    <strong>Outros Benefícios:</strong>{' '}
                    {funcionario.outros_beneficios}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
