import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Container,
  Typography,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Alert,
  Paper,
  Toolbar,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialFornecedorState = {
  nome_razao_social: '',
  nome_fantasia: '',
  cnpj: '',
  inscricao_estadual: '',
  data_fundacao: '',
  tipo_fornecedor: '',
  telefone_fixo: '',
  celular: '',
  email: '',
  website: '',
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  ramo_atividade: '',
  banco: '',
  agencia: '',
  conta_corrente: '',
  tipo_conta: '',
  nome_representante: '',
  cpf_representante: '',
  telefone_representante: '',
  email_representante: '',
  empresa_id: '', // Relaciona a empresa
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

export default function Fornecedores() {
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedor, setFornecedor] = useState(initialFornecedorState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL;

  const empresas = [
    { id: 1, nome: 'AABB Engenharia' },
    { id: 2, nome: 'Chalé' },
    { id: 3, nome: 'Concreto' },
  ];

  const fetchFornecedores = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/fornecedores`);
      setFornecedores(response.data);
    } catch (error) {
      console.error('Erro ao buscar fornecedores:', error);
      setSnackbarMessage('Erro ao buscar fornecedores');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchFornecedores();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFornecedor({ ...fornecedor, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axios.put(`${API_URL}/api/fornecedores/${fornecedor.id}`, fornecedor);
        setSnackbarMessage('Fornecedor atualizado com sucesso!');
      } else {
        fornecedor.website = fornecedor.website || null;
        fornecedor.complemento = fornecedor.complemento || null;
        fornecedor.telefone_fixo = fornecedor.telefone_fixo || null;
        fornecedor.empresa_id = fornecedor.empresa_id || null;

        await axios.post(`${API_URL}/api/fornecedores`, fornecedor);
        setSnackbarMessage('Fornecedor cadastrado com sucesso!');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setFornecedor(initialFornecedorState);
      setIsEditing(false);
      setOpen(false);
      fetchFornecedores();
    } catch (error) {
      console.error('Erro ao salvar fornecedor:', error);
      setSnackbarMessage('Erro ao salvar fornecedor');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEdit = (fornecedor) => {
    setFornecedor(fornecedor);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/api/fornecedores/${id}`);
      setSnackbarMessage('Fornecedor deletado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchFornecedores();
    } catch (error) {
      console.error('Erro ao deletar fornecedor:', error);
      setSnackbarMessage('Erro ao deletar fornecedor');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFornecedor(initialFornecedorState);
    setIsEditing(false);
  };
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const columns = [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'nome_razao_social', header: 'Nome/Razão Social' },
    { accessorKey: 'cnpj', header: 'CNPJ' },
    { accessorKey: 'telefone_fixo', header: 'Telefone Fixo' },
    { accessorKey: 'celular', header: 'Celular' },
    { accessorKey: 'email', header: 'Email' },
    
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <Header open={menuOpen} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de Fornecedores
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Novo Fornecedor
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={fornecedores}
                  enablePagination
                  enableSorting
                />
              </Paper>
            </Container>
          </Box>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Fornecedor' : 'Cadastrar Fornecedor'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* Nome/Razão Social */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome/Razão Social"
                    name="nome_razao_social"
                    value={fornecedor.nome_razao_social}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                {/* Nome Fantasia */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome Fantasia"
                    name="nome_fantasia"
                    value={fornecedor.nome_fantasia}
                    onChange={handleChange}
                  />
                </Grid>
                {/* CNPJ */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CNPJ"
                    name="cnpj"
                    value={fornecedor.cnpj}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                {/* Inscrição Estadual */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Inscrição Estadual"
                    name="inscricao_estadual"
                    value={fornecedor.inscricao_estadual}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Data de Fundação */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    fullWidth
                    label="Data de Fundação"
                    name="data_fundacao"
                    InputLabelProps={{ shrink: true }}
                    value={fornecedor.data_fundacao}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Tipo de Fornecedor */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Tipo de Fornecedor"
                    name="tipo_fornecedor"
                    value={fornecedor.tipo_fornecedor}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Pessoa Jurídica">Pessoa Jurídica</MenuItem>
                    <MenuItem value="Pessoa Física">Pessoa Física</MenuItem>
                  </TextField>
                </Grid>

                {/* Seleção de Empresa */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Selecione a Empresa</InputLabel>
                    <Select
                      name="empresa_id"
                      value={fornecedor.empresa_id}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Escolha uma empresa</em>
                      </MenuItem>
                      {empresas.map((empresa) => (
                        <MenuItem key={empresa.id} value={empresa.id}>
                          {empresa.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Telefone Fixo */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Telefone Fixo"
                    name="telefone_fixo"
                    value={fornecedor.telefone_fixo}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Celular */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Celular"
                    name="celular"
                    value={fornecedor.celular}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Email */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={fornecedor.email}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Website */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Website"
                    name="website"
                    value={fornecedor.website}
                    onChange={handleChange}
                  />
                </Grid>

                {/* CEP */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CEP"
                    name="cep"
                    value={fornecedor.cep}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Endereço */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Endereço"
                    name="endereco"
                    value={fornecedor.endereco}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Número */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Número"
                    name="numero"
                    value={fornecedor.numero}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Complemento */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    name="complemento"
                    value={fornecedor.complemento}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Bairro */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    name="bairro"
                    value={fornecedor.bairro}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Cidade */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="cidade"
                    value={fornecedor.cidade}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Estado */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Estado"
                    name="estado"
                    value={fornecedor.estado}
                    onChange={handleChange}
                  >
                    <MenuItem value="AC">Acre</MenuItem>
                    <MenuItem value="AL">Alagoas</MenuItem>
                    <MenuItem value="AP">Amapá</MenuItem>
                    <MenuItem value="AM">Amazonas</MenuItem>
                    <MenuItem value="BA">Bahia</MenuItem>
                    <MenuItem value="CE">Ceará</MenuItem>
                    <MenuItem value="DF">Distrito Federal</MenuItem>
                    <MenuItem value="ES">Espírito Santo</MenuItem>
                    <MenuItem value="GO">Goiás</MenuItem>
                    <MenuItem value="MA">Maranhão</MenuItem>
                    <MenuItem value="MT">Mato Grosso</MenuItem>
                    <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                    <MenuItem value="MG">Minas Gerais</MenuItem>
                    <MenuItem value="PA">Pará</MenuItem>
                    <MenuItem value="PB">Paraíba</MenuItem>
                    <MenuItem value="PR">Paraná</MenuItem>
                    <MenuItem value="PE">Pernambuco</MenuItem>
                    <MenuItem value="PI">Piauí</MenuItem>
                    <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                    <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                    <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                    <MenuItem value="RO">Rondônia</MenuItem>
                    <MenuItem value="RR">Roraima</MenuItem>
                    <MenuItem value="SC">Santa Catarina</MenuItem>
                    <MenuItem value="SP">São Paulo</MenuItem>
                    <MenuItem value="SE">Sergipe</MenuItem>
                    <MenuItem value="TO">Tocantins</MenuItem>
                  </TextField>
                </Grid>

                {/* Banco */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Banco"
                    name="banco"
                    value={fornecedor.banco}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Agência */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Agência"
                    name="agencia"
                    value={fornecedor.agencia}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Conta Corrente */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Conta Corrente"
                    name="conta_corrente"
                    value={fornecedor.conta_corrente}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Tipo de Conta */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Tipo de Conta"
                    name="tipo_conta"
                    value={fornecedor.tipo_conta}
                    onChange={handleChange}
                  >
                    <MenuItem value="Conta Corrente">Conta Corrente</MenuItem>
                    <MenuItem value="Conta Poupança">Conta Poupança</MenuItem>
                  </TextField>
                </Grid>

                {/* Nome do Representante */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome do Representante"
                    name="nome_representante"
                    value={fornecedor.nome_representante}
                    onChange={handleChange}
                  />
                </Grid>
                {/* CPF do Representante */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CPF do Representante"
                    name="cpf_representante"
                    value={fornecedor.cpf_representante}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Telefone do Representante */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Telefone do Representante"
                    name="telefone_representante"
                    value={fornecedor.telefone_representante}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Email do Representante */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email do Representante"
                    name="email_representante"
                    value={fornecedor.email_representante}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Botão de ação */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
