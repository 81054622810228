import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, MenuItem, Container, Typography,
  Grid, Modal, IconButton, Snackbar, Alert, CssBaseline, Toolbar, Paper,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();
const API_BASE_URL = process.env.REACT_APP_API_URL;

const initialProductState = {
  codigo_produto: '',
  nome_produto: '',
  descricao_produto: '',
  categoria: '',
  subcategoria: '',
  marca: '',
  modelo: '',
  dimensoes: '',
  peso: '',
  material: '',
  cor: '',
  unidade_medida: '',
  preco_custo: '',
  preco_venda: '',
  estoque_minimo: '',
  estoque_atual: '',
  localizacao_estoque: '',
  fornecedor_principal: '',
  codigo_fornecedor: '',
  preco_compra_fornecedor: '',
  lead_time_fornecimento: '',
  imagem_principal: '',
  imagens_adicionais: '',
  codigo_barras: '',
  sku: '',
  ncm: '',
  observacoes: '',
  cfop: '',
  cst: '',
  origem_produto: '',
  aliquota_icms: '',
  aliquota_ipi: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

export default function Produtos() {
  const [produtos, setProdutos] = useState([]);
  const [produto, setProduto] = useState(initialProductState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    fetchProdutos();
  }, []);

  // Buscar produtos da API
  const fetchProdutos = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/produtos`);
      setProdutos(response.data);
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    }
  };

  // Atualizar campos ao digitar
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduto({ ...produto, [name]: value });
  };

  // Enviar formulário
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const cleanedProduto = {
        ...produto,
        descricao_produto: produto.descricao_produto || null,
        categoria: produto.categoria || null,
        subcategoria: produto.subcategoria || null,
        marca: produto.marca || null,
        modelo: produto.modelo || null,
        dimensoes: produto.dimensoes || null,
        peso: produto.peso || null,
        material: produto.material || null,
        cor: produto.cor || null,
        unidade_medida: produto.unidade_medida || null,
        preco_custo: produto.preco_custo || 0,
        preco_venda: produto.preco_venda || 0,
        estoque_minimo: produto.estoque_minimo || null,
        estoque_atual: produto.estoque_atual || null,
        localizacao_estoque: produto.localizacao_estoque || null,
        fornecedor_principal: produto.fornecedor_principal || null,
        codigo_fornecedor: produto.codigo_fornecedor || null,
        preco_compra_fornecedor: produto.preco_compra_fornecedor || null,
        lead_time_fornecimento: produto.lead_time_fornecimento || null,
        imagem_principal: produto.imagem_principal || null,
        imagens_adicionais: produto.imagens_adicionais || null,
        codigo_barras: produto.codigo_barras || null,
        sku: produto.sku || null,
        ncm: produto.ncm || null,
        observacoes: produto.observacoes || null,
        cfop: produto.cfop || null,
        cst: produto.cst || null,
        origem_produto: produto.origem_produto || null,
        aliquota_icms: produto.aliquota_icms || null,
        aliquota_ipi: produto.aliquota_ipi || null,
      };

      if (isEditing) {
        await axios.put(`${API_BASE_URL}/api/produtos/${produto.id}`, cleanedProduto);
        setSnackbarMessage('Produto atualizado com sucesso!');
      } else {
        await axios.post(`${API_BASE_URL}/api/produtos`, cleanedProduto);
        setSnackbarMessage('Produto cadastrado com sucesso!');
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setProduto(initialProductState);
      setIsEditing(false);
      setOpen(false);
      fetchProdutos();
    } catch (error) {
      setSnackbarMessage('Erro ao salvar produto!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao salvar produto:', error.response ? error.response.data : error.message);
    }
  };

  const handleEdit = (produto) => {
    setProduto(produto);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/produtos/${id}`);
      setSnackbarMessage('Produto deletado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchProdutos();
    } catch (error) {
      setSnackbarMessage('Erro ao deletar produto!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao deletar produto:', error);
    }
  };

  const handleView = async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/produtos/${id}`);
      setProduto(response.data);
      setViewOpen(true);
    } catch (error) {
      console.error('Erro ao visualizar produto:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setProduto(initialProductState);
    setIsEditing(false);
  };
  const handleViewClose = () => setViewOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  const columns = [
    { accessorKey: 'codigo_produto', header: 'Código' },
    { accessorKey: 'nome_produto', header: 'Nome' },
    { accessorKey: 'categoria', header: 'Categoria' },
    { accessorKey: 'preco_venda', header: 'Preço de Venda' },
    { accessorKey: 'estoque_atual', header: 'Estoque Atual' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleView(row.original.id)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de Produtos
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Novo Produto
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={produtos}
                  enableColumnOrdering
                  enableRowSelection
                  enablePagination
                  enableSorting
                  muiTableBodyRowProps={{ hover: true }}
                  muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                  pageSizeOptions={[10, 25, 50]}
                  initialState={{ pagination: { pageSize: 50 } }}
                />
              </Paper>
            </Container>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Produto' : 'Cadastrar Produto'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Código do Produto"
                    name="codigo_produto"
                    value={produto.codigo_produto}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome do Produto"
                    name="nome_produto"
                    value={produto.nome_produto}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Descrição"
                    name="descricao_produto"
                    value={produto.descricao_produto}
                    onChange={handleChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Categoria"
                    name="categoria"
                    value={produto.categoria}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Subcategoria"
                    name="subcategoria"
                    value={produto.subcategoria}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Marca"
                    name="marca"
                    value={produto.marca}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Modelo"
                    name="modelo"
                    value={produto.modelo}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Dimensões"
                    name="dimensoes"
                    value={produto.dimensoes}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Peso"
                    name="peso"
                    value={produto.peso}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Material"
                    name="material"
                    value={produto.material}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cor"
                    name="cor"
                    value={produto.cor}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Unidade de Medida"
                    name="unidade_medida"
                    value={produto.unidade_medida}
                    onChange={handleChange}
                  >
                    <MenuItem value="Unidade">Unidade</MenuItem>
                    <MenuItem value="Metro">Metro</MenuItem>
                    <MenuItem value="Quilograma">Quilograma</MenuItem>
                    <MenuItem value="Litro">Litro</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preço de Custo"
                    name="preco_custo"
                    value={produto.preco_custo}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preço de Venda"
                    name="preco_venda"
                    value={produto.preco_venda}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Estoque Mínimo"
                    name="estoque_minimo"
                    value={produto.estoque_minimo}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Estoque Atual"
                    name="estoque_atual"
                    value={produto.estoque_atual}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Localização no Estoque"
                    name="localizacao_estoque"
                    value={produto.localizacao_estoque}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Fornecedor Principal"
                    name="fornecedor_principal"
                    value={produto.fornecedor_principal}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Código do Fornecedor"
                    name="codigo_fornecedor"
                    value={produto.codigo_fornecedor}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preço de Compra do Fornecedor"
                    name="preco_compra_fornecedor"
                    value={produto.preco_compra_fornecedor}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Lead Time de Fornecimento"
                    name="lead_time_fornecimento"
                    value={produto.lead_time_fornecimento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Código de Barras"
                    name="codigo_barras"
                    value={produto.codigo_barras}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="SKU"
                    name="sku"
                    value={produto.sku}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="NCM"
                    name="ncm"
                    value={produto.ncm}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CFOP"
                    name="cfop"
                    value={produto.cfop}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CST"
                    name="cst"
                    value={produto.cst}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Origem do Produto"
                    name="origem_produto"
                    value={produto.origem_produto}
                    onChange={handleChange}
                  >
                    <MenuItem value="Nacional">Nacional</MenuItem>
                    <MenuItem value="Importado">Importado</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Alíquota de ICMS"
                    name="aliquota_icms"
                    value={produto.aliquota_icms}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Alíquota de IPI"
                    name="aliquota_ipi"
                    value={produto.aliquota_ipi}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Observações"
                    name="observacoes"
                    value={produto.observacoes}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
        <Modal
          open={viewOpen}
          onClose={handleViewClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleViewClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Visualizar Produto
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Código:</strong> {produto.codigo_produto}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Nome:</strong> {produto.nome_produto}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Categoria:</strong> {produto.categoria}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Preço de Venda:</strong> {produto.preco_venda}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Estoque Atual:</strong> {produto.estoque_atual}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Observações:</strong> {produto.observacoes}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Descrição:</strong> {produto.descricao_produto}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
