import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, MenuItem, Container, Typography, Grid, Modal,
  Snackbar, Alert, Paper, IconButton, Toolbar, CssBaseline, Select,
  InputLabel, FormControl, Checkbox, ListItemText, OutlinedInput
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const telasDisponiveis = [
  { id: 'dashboard', nome: 'Dashboard' },
  { id: 'clientes', nome: 'Clientes' },
  { id: 'financeiro', nome: 'Financeiro' },
  { id: 'relatorios', nome: 'Relatórios' }
];

const funcoesDisponiveis = ['Visualizar', 'Editar', 'Remover'];

const initialUserState = {
  nome: '',
  email: '',
  login: '',
  senha: '',
  perfil_id: '',
  status_id: '',
  empresas: [] // [{ empresa_id, nivel_acesso_id, funcoes: [], telas: [] }]
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

const empresasDisponiveis = [
  { id: 1, nome: 'AABB Engenharia' },
  { id: 2, nome: 'AABB Concreto' },
  { id: 3, nome: 'AABB Chalé' }
];

const niveisAcesso = [
  { id: 1, nome: 'Admin' },
  { id: 2, nome: 'Gerente' },
  { id: 3, nome: 'Usuário' },
];

export default function Usuarios() {
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState(initialUserState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const fetchUsuarios = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/usuarios`);
      setUsuarios(response.data);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuario((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEmpresaChange = (event) => {
    const selectedIds = event.target.value;

    setUsuario((prevState) => ({
      ...prevState,
      empresas: selectedIds.map(id => {
        const existingEmpresa = prevState.empresas.find(emp => emp.empresa_id === id);
        return existingEmpresa ? existingEmpresa : { empresa_id: id, nivel_acesso_id: '', funcoes: [], telas: [] };
      })
    }));
  };

  const handleNivelAcessoChange = (empresaId, nivelAcessoId) => {
    setUsuario(prevState => ({
      ...prevState,
      empresas: prevState.empresas.map(emp =>
        emp.empresa_id === empresaId ? { ...emp, nivel_acesso_id: nivelAcessoId } : emp
      )
    }));
  };

  const handleFuncoesChange = (empresaId, selectedFuncoes) => {
    setUsuario(prevState => ({
      ...prevState,
      empresas: prevState.empresas.map(emp =>
        emp.empresa_id === empresaId ? { ...emp, funcoes: selectedFuncoes } : emp
      )
    }));
  };

  const handleTelasChange = (empresaId, selectedTelas) => {
    setUsuario(prevState => ({
      ...prevState,
      empresas: prevState.empresas.map(emp =>
        emp.empresa_id === empresaId ? { ...emp, telas: selectedTelas } : emp
      )
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_API_URL}/usuarios/${usuario.id}`, usuario);
        setSnackbarMessage('Usuário atualizado com sucesso!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/usuarios`, usuario);
        setSnackbarMessage('Usuário cadastrado com sucesso!');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setUsuario(initialUserState);
      setIsEditing(false);
      setOpen(false);
      fetchUsuarios();
    } catch (error) {
      setSnackbarMessage('Erro ao salvar usuário!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao salvar usuário:', error);
    }
  };

  const handleEdit = (usuario) => {
    setUsuario(usuario);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/usuarios/${id}`);
      setSnackbarMessage('Usuário deletado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchUsuarios();
    } catch (error) {
      setSnackbarMessage('Erro ao deletar usuário!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao deletar usuário:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUsuario(initialUserState);
    setIsEditing(false);
  };
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => setMenuOpen(!menuOpen);

  const columns = [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'nome', header: 'Nome do Usuário' },
    { accessorKey: 'email', header: 'Email' },
    { accessorKey: 'login', header: 'Login' },
    { accessorKey: 'perfil_id', header: 'Perfil de Acesso' },
    { accessorKey: 'status_id', header: 'Status' },
    { accessorKey: 'data_criacao', header: 'Data de Criação' },
    { accessorKey: 'ultimo_acesso', header: 'Último Acesso' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de Usuários
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Novo Usuário
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={usuarios}
                  enableColumnOrdering
                  enablePagination
                  enableSorting
                />
              </Paper>
            </Container>
          </Box>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Usuário' : 'Cadastrar Usuário'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* Dados Pessoais */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome do Usuário"
                    name="nome"
                    value={usuario.nome}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={usuario.email}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Login"
                    name="login"
                    value={usuario.login}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="password"
                    label="Senha"
                    name="senha"
                    value={usuario.senha}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                {/* Perfil e Status */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Perfil de Acesso"
                    name="perfil_id"
                    value={usuario.perfil_id}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="1">Administrador</MenuItem>
                    <MenuItem value="2">Usuário</MenuItem>
                    <MenuItem value="3">Gerente</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Status do Usuário"
                    name="status_id"
                    value={usuario.status_id}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="1">Ativo</MenuItem>
                    <MenuItem value="2">Inativo</MenuItem>
                  </TextField>
                </Grid>

                {/* Empresas e Nível de Acesso */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Empresas</InputLabel>
                    <Select
                      multiple
                      name="empresas"
                      value={usuario.empresas.map(emp => emp.empresa_id)}
                      onChange={handleEmpresaChange}
                      input={<OutlinedInput label="Empresas" />}
                      renderValue={(selected) =>
                        selected
                          .map(id => empresasDisponiveis.find(e => e.id === id)?.nome)
                          .join(', ')
                      }
                    >
                      {empresasDisponiveis.map((empresa) => (
                        <MenuItem key={empresa.id} value={empresa.id}>
                          <Checkbox checked={usuario.empresas.some(e => e.empresa_id === empresa.id)} />
                          <ListItemText primary={empresa.nome} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Níveis de Acesso para Empresas */}
                {usuario.empresas.map(({ empresa_id, nivel_acesso_id, funcoes = [], telas = [] }) => (
                  <React.Fragment key={empresa_id}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label={`Nível de Acesso - ${empresasDisponiveis.find(e => e.id === empresa_id)?.nome}`}
                        value={nivel_acesso_id}
                        onChange={(e) => handleNivelAcessoChange(empresa_id, e.target.value)}
                      >
                        {niveisAcesso.map(nivel => (
                          <MenuItem key={nivel.id} value={nivel.id}>
                            {nivel.nome}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Funções de Acesso */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Funções Permitidas</InputLabel>
                        <Select
                          multiple
                          value={funcoes}
                          onChange={(e) => handleFuncoesChange(empresa_id, e.target.value)}
                          input={<OutlinedInput label="Funções Permitidas" />}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {funcoesDisponiveis.map((funcao) => (
                            <MenuItem key={funcao} value={funcao}>
                              <Checkbox checked={funcoes?.indexOf(funcao) > -1} />
                              <ListItemText primary={funcao} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Telas Permitidas */}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Telas Permitidas</InputLabel>
                        <Select
                          multiple
                          value={telas}
                          onChange={(e) => handleTelasChange(empresa_id, e.target.value)}
                          input={<OutlinedInput label="Telas Permitidas" />}
                          renderValue={(selected) =>
                            selected
                              .map(id => telasDisponiveis.find(tela => tela.id === id)?.nome)
                              .join(', ')
                          }
                        >
                          {telasDisponiveis.map((tela) => (
                            <MenuItem key={tela.id} value={tela.id}>
                              <Checkbox checked={telas?.indexOf(tela.id) > -1} />
                              <ListItemText primary={tela.nome} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
