import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, MenuItem, Container, Typography,
  Grid, Modal, IconButton, Snackbar, Alert, CssBaseline, Toolbar, Paper,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../Header';
import Menu from '../../Menu';

const theme = createTheme();

const initialCategoryState = {
  nome: '',
  tipo: 'receita',
  descricao: '',
  data_criacao: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: '8px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
};

export default function CategoriaTransacoes() {
  const [categorias, setCategorias] = useState([]);
  const [categoria, setCategoria] = useState(initialCategoryState);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    fetchCategorias();
  }, []);

  const fetchCategorias = async () => {
    try {
      const response = await axios.get('https://api.aabbengenharia.com.br/api/financeiro/categorias');
      setCategorias(response.data);
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoria({ ...categoria, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEditing) {
        await axios.put(`https://api.aabbengenharia.com.br/api/financeiro/categorias/${categoria.id}`, categoria);
        setSnackbarMessage('Categoria atualizada com sucesso!');
      } else {
        await axios.post('https://api.aabbengenharia.com.br/api/financeiro/categorias', categoria);
        setSnackbarMessage('Categoria cadastrada com sucesso!');
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setCategoria(initialCategoryState);
      setIsEditing(false);
      setOpen(false);
      fetchCategorias();
    } catch (error) {
      setSnackbarMessage('Erro ao salvar categoria!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao salvar categoria:', error.response ? error.response.data : error.message);
    }
  };

  const handleEdit = (categoria) => {
    setCategoria(categoria);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.aabbengenharia.com.br/api/financeiro/categorias/${id}`);
      setSnackbarMessage('Categoria deletada com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchCategorias();
    } catch (error) {
      setSnackbarMessage('Erro ao deletar categoria!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Erro ao deletar categoria:', error);
    }
  };

  const handleView = async (id) => {
    try {
      const response = await axios.get(`https://api.aabbengenharia.com.br/api/financeiro/categorias/${id}`);
      setCategoria(response.data);
      setViewOpen(true);
    } catch (error) {
      console.error('Erro ao visualizar categoria:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCategoria(initialCategoryState);
    setIsEditing(false);
  };
  const handleViewClose = () => setViewOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  const columns = [
    { accessorKey: 'nome', header: 'Nome' },
    { accessorKey: 'tipo', header: 'Tipo' },
    { accessorKey: 'descricao', header: 'Descrição' },
    { accessorKey: 'data_criacao', header: 'Data de Criação' },
    {
      accessorKey: 'actions',
      header: 'Ações',
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleView(row.original.id)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <CssBaseline />
        <Header open={menuOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Menu open={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h4" gutterBottom>
                Categorias de Transações
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Nova Categoria
                </Button>
              </Box>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <MaterialReactTable
                  columns={columns}
                  data={categorias}
                  enableColumnOrdering
                  enableRowSelection
                  enablePagination
                  enableSorting
                  muiTableBodyRowProps={{ hover: true }}
                  muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                  pageSizeOptions={[10, 25, 50]}
                  initialState={{ pagination: { pageSize: 50 } }}
                />
              </Paper>
            </Container>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEditing ? 'Editar Categoria' : 'Cadastrar Categoria'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="nome"
                    value={categoria.nome}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Tipo"
                    name="tipo"
                    value={categoria.tipo}
                    onChange={handleChange}
                  >
                    <MenuItem value="receita">Receita</MenuItem>
                    <MenuItem value="despesa">Despesa</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Descrição"
                    name="descricao"
                    value={categoria.descricao}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Data de Criação"
                    name="data_criacao"
                    value={categoria.data_criacao}
                    onChange={handleChange}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
        <Modal
          open={viewOpen}
          onClose={handleViewClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton sx={closeButtonStyle} onClick={handleViewClose}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Visualizar Categoria
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Nome:</strong> {categoria.nome}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>Tipo:</strong> {categoria.tipo}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <strong>Descrição:</strong> {categoria.descricao}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <strong>Data de Criação:</strong> {categoria.data_criacao}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
